import * as types from "./types";

const baseRoute = "v1/payment";

export const searchPayments = (page, size, filters = {}, sort = {}) => {
  return (api) => ({
    type: types.SEARCH_PAYMENTS,
    payload: api.post(`${baseRoute}/search`, { page, size, filters, sort }),
  });
};

export const createPayment = (values) => {
  return (api) => ({
    type: types.CREATE_PAYMENT,
    payload: api.post(baseRoute, values),
    success: {
      message: "createPayment",
      description: "createPaymentSuccess",
    },
    showUserErrors: true
  });
};

export const fetchPayment = (id) => {
  return (api) => ({
    type: types.FETCH_PAYMENT,
    payload: api.get(`${baseRoute}/${id}`)
  });
};

export const updatePayment = (id, values) => {
  return (api) => ({
    type: types.UPDATE_PAYMENT,
    payload: api.put(`${baseRoute}/${id}`, values),
    success: {
      message: "updatePayment",
      description: "updatePaymentSuccess",
    },

  });
};

export const deletePayment = (id) => {
  return (api) => ({
    type: types.DELETE_PAYMENT,
    payload: api.delete(`${baseRoute}/${id}`),
    success: {
      message: "deletePayment",
      description: "deletePaymentSuccess",
    },
  });
};

export const fetchPublicPayment = (id) => {
  return (api) => ({
    type: types.FETCH_PUBLIC_PAYMENT,
    payload: api.get(`${baseRoute}/public/${id}`),
    showUserErrors: true
  });
};

export const forwardPayment = (id, emails) => {
  return (api) => ({
    type: types.FORWARD_PAYMENT,
    payload: api.post(`${baseRoute}/forward/${id}`, { emails }),
    showUserErrors: true,
    success: {
      message: 'forwardPayment',
      description: 'forwardPaymentSuccess'
    }
  });
};

export const generatePublicIdPayment = (id) => {
  return (api) => ({
    type: types.GENERATE_PUBLIC_ID_PAYMENT,
    payload: api.post(`${baseRoute}/generatePublicId/${id}`),
    showUserErrors: true,
  });
};

export const cancelPayment = (id) => {
  return (api) => ({
    type: types.CANCEL_PAYMENT,
    payload: api.put(`${baseRoute}/cancel/${id}`),
    success: {
      message: "cancelPayment",
      description: "cancelSuccess",
    },
  });
};
