import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Switch } from "antd";
import { useIntl } from "react-intl";
import _ from "lodash";
import moment from 'moment';

import { deleteJobFromInvoice, searchJobs } from "../../redux/actions"

import JobTable from "../Jobs/JobTable";
import Clients from "../Clients";

const { TextArea } = Input;
const { Option } = Select;

export default ({ clientParam, editing = false, getBreakdown, form, invoiceId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config, shallowEqual);

  //  Local State
  const [client, setClient] = useState(form.getFieldValue('client'));
  const [jobs, setJobs] = useState(form.getFieldValue('jobs') || []);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState(form.getFieldValue('jobs') || []);
  const [clientsModalVisible, setClientsModalVisible] = useState(false);
  const [breakdown, setBreakdown] = useState(form.getFieldValue('breakdown') || {});
  const requiredRule = { required: true, message: intl.formatMessage({ id: "fieldRequired" }) };
  const [subscription, setSubscription] = useState(false);

  const onClientSelection = (value, row) => {
    setJobs([]);
    setSelectedJobs([]);
    setClient(row[0]);
    form.setFieldsValue({ client: row[0]._id, jobs: [] });
    setClientsModalVisible(false);
    //  Fetch Client Non Invoiced Jobs
    setLoadingJobs(true);
    dispatch(searchJobs(1, 9999999, { client: row[0]._id, invoice: [], endDate: { $exists: true }, subscription: false }))
      .then(({ payload }) => {
        setJobs(payload.data);
        setLoadingJobs(false);
      })
      .catch((err) => console.log(err));
  };

  const updateBreakdown = ({ force = false, roundBelow5cents = false }) => {
    let { total, subscription, invoiceType } = form.getFieldsValue();
    const isInvoiceTypeOther = invoiceType === "other";
    const { subscriptionInvoice } = {...client};
    let subTotal;
    let discount;
    let netTotal;
    let taxTotal;
    if (subscription && subscriptionInvoice && subscriptionInvoice.enabled && subscriptionInvoice.amount) {
      subTotal = _.round(subscriptionInvoice.amount || 0, 2);
    } else {
      subTotal = _.round(_.sumBy(selectedJobs, 'total'), 2);
    }
    if (!total || force) {
      taxTotal = isInvoiceTypeOther ? 0 : _.round(subTotal * (config.taxValue / 100), 2);
      total = _.round(subTotal + taxTotal, 2).toFixed(2);
      netTotal = _.round(subTotal, 2);
      discount = 0;
      if (roundBelow5cents) {
          const totalCents = total.toString().split(".")[1]
          if (+totalCents < 5) {
          total = _.round(total, 0);
          netTotal = _.round(total / (1 + (config.taxValue / 100)), 2);
          taxTotal = isInvoiceTypeOther ? 0 : _.round(total - netTotal, 2);
          discount = _.round(subTotal - netTotal, 2);
        }
      }
    } else {
      netTotal = _.round(total / (1 + (config.taxValue / 100)), 2);
      taxTotal = isInvoiceTypeOther ? 0 : _.round(total - netTotal, 2);
      discount = _.round(subTotal - netTotal, 2);
    }
    form.setFieldsValue({ total });
    setBreakdown({
      subTotal,
      discount,
      netTotal,
      taxTotal,
      taxValue: config.taxValue,
      total,
    });
    if (getBreakdown) {
      getBreakdown({
        subTotal,
        discount,
        netTotal,
        taxTotal,
        taxValue: config.taxValue,
        total,
      });
    }
  };

  const onJobsSelection = (values, rows) => {
    setSelectedJobs(rows);
    form.setFieldsValue({ jobs: rows });
  };


  const onRemoveJob = (jobId) => {
    dispatch(deleteJobFromInvoice(invoiceId, jobId))
      .then(() => {
        const newJobs = _.filter(jobs, (j) => j._id !== jobId)
        setJobs(newJobs)
        const newSelectedJobs = _.filter(selectedJobs, (j) => j._id !== jobId)
        setSelectedJobs(newSelectedJobs)
        form.setFieldsValue({ jobs: newSelectedJobs })
      })
      .catch((err) => console.log(err))
  }
  const renderClientName = () => {
    if (!client) return intl.formatMessage({ id: "selectClient" });
    let clientName = `${_.get(client, 'firstName', '')} ${_.get(client, 'lastName', '')}`;
    if (client.companyName) clientName += `[${client.companyName}]`;
    return clientName;
  };

  useEffect(() => {
    if (!editing) updateBreakdown({ force: true, roundBelow5cents: true });
  }, [selectedJobs]);

  useEffect(() => {
    if (!_.isEmpty(clientParam)) {
      onClientSelection(null, [{ ...clientParam }]);
    }
  }, [clientParam]);

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        initialValues={{ date: moment(), invoiceType: 'invoice' }}
      >
        <Row gutter="16">
          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <Form.Item name="client"  label={intl.formatMessage({ id: "client" })} rules={[requiredRule]}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Input value={renderClientName()} disabled />
                <Button
                  disabled={editing}
                  type="primary"
                  onClick={() => setClientsModalVisible(true)}
                >
                  {intl.formatMessage({ id: "select" })}
                </Button>
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={5}>
            <Form.Item
              name="date"
              label={intl.formatMessage({ id: "date" })}
              rules={[requiredRule]}
            >
              <DatePicker allowClear style={{ width: "100%" }} format={'DD/MM/YYYY'} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={4}>
            <Form.Item
              name="dueDate"
              label={intl.formatMessage({ id: "dueDate" })}
            >
              <DatePicker allowClear style={{ width: "100%" }} format={'DD/MM/YYYY'} disabledDate={current => current && current.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={3}>
            <Form.Item
              name="showDates"
              label={intl.formatMessage({ id: "showDates" })}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="invoiceType"
              label={intl.formatMessage({ id: "invoiceType" })}
              rules={[requiredRule]}
            >
              <Select disabled={editing} onChange={() => updateBreakdown({ force: true })}>
                <Option key="invoice" value="invoice">{intl.formatMessage({ id: 'invoice' })}</Option>
                <Option key="other" value="other">{intl.formatMessage({ id: 'other' })}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item name="jobs" label={intl.formatMessage({ id: "jobs" })} rules={[requiredRule]}>
              <JobTable data={{ data: jobs }} loading={loadingJobs} onRemoveJob={editing && onRemoveJob} onRowSelection={!editing && onJobsSelection} rowSelectionType='checkbox' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              title={intl.formatMessage({ id: "notes" })}
              style={{ width: "100%", marginBottom: 16 }}
            >
              <Form.Item name="notes">
                <TextArea rows={5} />
              </Form.Item>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              title={intl.formatMessage({ id: "breakdown" })}
              style={{ width: "100%", marginBottom: 16 }}
              bodyStyle={{ display: "flex", justifyContent: "flex-end" }}
            >
              <table className="invoice-below-table" style={{ width: "100%" }}>
                <tbody>
                {client && _.get(client, 'subscriptionInvoice.enabled') && _.get(client, 'subscriptionInvoice.amount') && (
                <tr>
                  <td>{intl.formatMessage({ id: "subscriptionAmount" })}: €{_.get(client, 'subscriptionInvoice.amount')} ({subscription ? intl.formatMessage({ id: 'enabled' }) : intl.formatMessage({ id: 'disabled' })})</td>
                  <td><Form.Item name="subscription" valuePropName="checked" style={{ marginBottom: 0 }}>
                    <Switch onChange={(value) => { setSubscription(value); updateBreakdown({}); }} />
                  </Form.Item></td>
                </tr>
                )}
                <tr>
                  <td>{intl.formatMessage({ id: "subTotal" })}:</td>
                  <td>€{_.round(_.get(breakdown, "subTotal"), 2)}</td>
                </tr>
                <tr>
                  <td>{intl.formatMessage({ id: "discount" })}:</td>
                  <td>€{_.round(_.get(breakdown, "discount"), 2)}</td>
                </tr>
                <tr>
                  <td>{intl.formatMessage({ id: "totalNetWorth" })}:</td>
                  <td>€{_.round(_.get(breakdown, "netTotal"), 2)}</td>
                </tr>
                <tr>
                  <td>
                    {intl.formatMessage({ id: "vat" })} ({config.taxValue}%):
                  </td>
                  <td>€{_.round(_.get(breakdown, "taxTotal"), 2)}</td>
                </tr>
                <tr>
                  <td>{intl.formatMessage({ id: "total" })}:</td>
                  <td>
                    <Form.Item name="total" noStyle={true} initialValue={editing ? breakdown.total : 0}>
                      <InputNumber
                        min={0}
                        formatter={value => `€ ${value}`}
                        onChange={(value) => updateBreakdown({})}
                        precision={2}
                      />
                    </Form.Item>
                    <Button
                      key="recalculate"
                      onClick={() => updateBreakdown({ force: true })}
                      type="primary"
                    >
                      {intl.formatMessage({ id: "recalculate" })}
                    </Button>
                  </td>
                </tr>
                </tbody>
              </table>
            </Card>
          </Col>
        </Row>
      </Form>

      <Modal
        bodyStyle={{ padding: 0 }}
        footer={null}
        onCancel={() => setClientsModalVisible(false)}
        title={intl.formatMessage({ id: "selectClient" })}
        visible={clientsModalVisible}
        width="75%"
        mask={true}
      >
        <Clients hideHeader={true} onRowSelection={onClientSelection} />
      </Modal>
    </>
  )
}
