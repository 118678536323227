import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, Typography } from "antd";
import moment from 'moment';
import _ from 'lodash';

import ArchiveTable from "../../components/ArchiveTable";

const { Paragraph } = Typography;

const dateRenderer = (date) => {
  if (!date) return;
  return moment(date).format('DD/MM/YYYY');
};

const renderBalance = (record) => {
  const total = _.get(record, 'breakdown.total');
  const amountPaid = record.amountPaid || 0;
  return `€${_.round(total - amountPaid, 2).toFixed(2)}`;
};

export default ({ data, fetchData, filters }) => {
  const intl = useIntl();
  const history = useHistory();

  const columns = [
    {
      title: intl.formatMessage({ id: "actions" }),
      className: "actions-column",
      render: (record) => (
        <div>
          <Button
            type="link"
            onClick={() => history.push(`/invoices/${record._id}`)}
            style={{ marginRight: 8 }}
          >
            {intl.formatMessage({ id: "view" })}
          </Button>
          <Button
            type="link"
            onClick={() => history.push(`/invoices/edit/${record._id}`)}
          >
            {intl.formatMessage({ id: "edit" })}
          </Button>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: "ref" }),
      dataIndex: "_ref",
    },
    {
      title: intl.formatMessage({ id: "client" }),
      render: (record) => _.get(record, 'client._id') ? (
        <Button
          type="link"
          onClick={() => history.push(`/clients/${_.get(record, 'client._id')}`)}
          style={{ marginRight: 8 }}
        >
          #{_.get(record, 'client._ref')} {_.get(record, 'client.firstName')} {_.get(record, 'client.lastName')} {_.get(record, 'clientCompanyName') ? `[${_.get(record, 'clientCompanyName')}]` : ''}
        </Button>
      ) : null,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: "date" }),
      render: (record) => dateRenderer(record.date)
    },
    {
      title: intl.formatMessage({ id: "dueDate" }),
      render: (record) => dateRenderer(record.dueDate)
    },
    {
      title: intl.formatMessage({ id: "invoiceType" }),
      render: (record) => record.invoiceType ? intl.formatMessage({ id: record.invoiceType }) : '',
    },
    {
      title: intl.formatMessage({ id: "subscriptionInvoice" }),
      render: (record) => record.subscription ? intl.formatMessage({ id: 'yes' }) : intl.formatMessage({ id: 'no' }),
    },
    {
      title: intl.formatMessage({ id: "net" }),
      render: (record) => `€${_.get(record, 'breakdown.netTotal', 0).toFixed(2)}`,
      width: 120,
    },
    {
      title: intl.formatMessage({ id: "vat" }),
      render: (record) => `€${_.get(record, 'breakdown.taxTotal', 0).toFixed(2)} (${_.get(record, 'breakdown.taxValue', '?')}%)`,
      width: 120,
    },
    {
      title: intl.formatMessage({ id: "total" }),
      render: (record) => `€${_.get(record, 'breakdown.total', 0).toFixed(2)}`,
      width: 120,
    },
    {
      title: intl.formatMessage({ id: "amountPaid" }),
      render: (record) => `€${_.get(record, 'amountPaid', 0).toFixed(2)}`,
    },
    {
      title: intl.formatMessage({ id: "balance" }),
      render: (record) => renderBalance(record),
    },
    {
      title: intl.formatMessage({ id: "status" }),
      render: (record) => record.status ? intl.formatMessage({ id: record.status }) : ''
    },
    {
      title: intl.formatMessage({ id: "lastSent" }),
      render: (record) => dateRenderer(record.lastSent)
    },
    {
      title: intl.formatMessage({ id: "notes" }),
      render: (record) => record.notes ? <Paragraph ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}>{record.notes}</Paragraph> : null
    },
  ];

  return (
    <ArchiveTable
      filters={filters}
      fetchData={fetchData}
      columns={columns}
      data={data}
    />
  );
};
