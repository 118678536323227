import { combineReducers } from 'redux';

import * as types from '../actions/types';
import auth from './auth';
import client from './client';
import clientItem from './clientItem';
import config from './config';
import invoice from './invoice';
import item from './item';
import job from './job';
import payment from './payment';
import system from './system';

const appReducer = combineReducers({
  ...auth,
  ...client,
  ...clientItem,
  ...config,
  ...invoice,
  ...item,
  ...job,
  ...payment,
  ...system,
});

export default (state = {}, action) => {
  if (action.type === types.RESET) {
    state = undefined
  }
  return appReducer(state, action)
};
