import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Form, Modal, notification, PageHeader } from "antd";
import { useIntl } from "react-intl";
import { useLocation, useHistory } from "react-router-dom";
import _ from 'lodash';

import { createInvoice, searchItems } from "../../../redux/actions";
import InvoiceForm from "../InvoiceForm";
import InvoiceView from "../View";

export default () => {
  const location = useLocation();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [preview, setPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const client = useSelector((state) => state.clients.data[previewData.client], shallowEqual);
  const services = useSelector((state) => state.items.data, shallowEqual);
  const [breakdown, setBreakdown] = useState({});

  const onCreate = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      values.discount = breakdown.discount;
      setSubmitting(true);
      await dispatch(createInvoice({ ...values, breakdown, jobs: values.jobs.map((j) => j._id) }));
      history.push(`/invoices`);
    } catch (err) {
      setSubmitting(false);
      console.log(err);
    }
  };

  const onPreview = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      setPreviewData({
        ...values,
        jobs: values.jobs.map((j) => ({
          ...j,
          items: j.items.map((i) => ({
            ...i,
            item: services[i.item],
          })),
        })),
        breakdown,
      });
      setPreview(true);
    } catch ({ message }) {
      notification.error({ message });
      setPreview(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(services)) return;
    dispatch(searchItems(1, 100));
  }, []);

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "createInvoice" })}
        extra={[
          <Button
            key="preview"
            type="default"
            disabled={submitting}
            onClick={onPreview}
          >
            {intl.formatMessage({ id: "preview" })}
          </Button>,
          <Button
            key="create"
            type="primary"
            disabled={submitting}
            onClick={onCreate}
          >
            {intl.formatMessage({ id: "create" })}
          </Button>,
        ]}
      />
      <InvoiceForm clientParam={_.get(location, "state.client")} form={form} getBreakdown={setBreakdown} />
      <Modal
        bodyStyle={{ padding: 0 }}
        footer={null}
        onCancel={() => setPreview(false)}
        title={intl.formatMessage({ id: "preview" })}
        visible={preview}
        width="75%"
        mask={true}
        destroyOnClose={true}
      >
        <InvoiceView data={{ ...previewData, client }} publicInvoice={true} />
      </Modal>
    </div>
  )
}
