import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, PageHeader } from "antd";
import { useIntl } from "react-intl";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { createJob } from "../../../redux/actions";
import JobForm from "../JobForm";

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [creating, setCreating] = useState(false);
  const create = async () => {
    await form.validateFields();
    const job = form.getFieldsValue();
    setCreating(true);
    dispatch(createJob(job))
      .then(({ payload }) => history.push(`/jobs/${payload._id}`))
      .catch(() => setCreating(false));
  };
  const [form] = Form.useForm();
  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "createJob" })}
        extra={[
          <Button
            key="create"
            type="primary"
            onClick={create}
            loading={creating}
          >
            {intl.formatMessage({ id: "create" })}
          </Button>,
        ]}
      />
      <JobForm
        defaultValues={{ items: [{}], startDate: moment() }}
        form={form}
      />
    </div>
  );
};
