import * as types from "./types";

const baseRoute = "v1/item";

export const searchItems = (page, size, filters = {}, sort = {}) => {
  return (api) => ({
    type: types.SEARCH_ITEMS,
    payload: api.post(`${baseRoute}/search`, { page, size, filters, sort }),
  });
};

export const fetchItem = (id) => {
  return (api) => ({
    type: types.FETCH_ITEM,
    payload: api.get(`${baseRoute}/${id}`),
  });
};

export const createItem = (data) => {
  return (api) => ({
    type: types.CREATE_ITEM,
    payload: api.post(baseRoute, data),
    success: {
      message: "createItem",
      description: "createSuccess",
    },
  });
};

export const updateItem = (id, data) => {
  return (api) => ({
    type: types.UPDATE_ITEM,
    payload: api.put(`${baseRoute}/${id}`, data),
    success: {
      message: 'updateItem',
      description: 'updateSuccess'
    }
  });
};

export const deleteItem = (id) => {
  return (api) => ({
    type: types.DELETE_ITEM,
    payload: api.delete(`${baseRoute}/${id}`),
    id,
    success: {
      message: 'deleteItem',
      description: 'deleteSuccess'
    }
  });
};