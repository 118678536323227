import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Card, Checkbox, Col, Form, PageHeader, Popconfirm, Row, Spin, Table } from "antd";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import copy from 'copy-to-clipboard';
import _ from "lodash";
import moment from 'moment';

import { cancelPayment, fetchPayment, fetchPublicPayment, forwardPayment, generatePublicIdPayment, sendNotification } from "../../../redux/actions";
import Modal from "antd/lib/modal/Modal";

export default ({ publicPayment = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [cancelling, setCancelling] = useState(false);
  const [forwarding, setForwarding] = useState(false);
  const [forwardModalVisible, setForwardModalVisible] = useState(false);

  const [forwardForm] = Form.useForm();

  const config = useSelector((state) => state.config, shallowEqual);
  const [payment, setPayment] = useState(null);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const renderDate = (date) => moment(date).format('DD/MM/YYYY');

  useEffect(() => {
    (publicPayment ? dispatch(fetchPublicPayment(id)) : dispatch(fetchPayment(id)))
      .then(({ payload }) => {
        setPayment(payload);
        setLoading(false);
      });
  }, []);

  if (loading) return <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} size="large" />;

  const columns = [
    {
      title: intl.formatMessage({ id: "paymentFor" }),
      render: ({ invoice }) => {
        return `${intl.formatMessage({ id: 'invoice'})} #${_.get(invoice,'_ref')}`;
      }
    },
    {
      title: intl.formatMessage({ id: "invoiceDate" }),
      render: ({ invoice }) => renderDate(_.get(invoice, "date"))
    },
    {
      title: intl.formatMessage({ id: "invoiceTotal" }),
      render: ({ invoice }) => `€${_.get(invoice, 'breakdown.total', 0).toFixed(2)}`,
    },
    {
      title: intl.formatMessage({ id: "paymentAmount" }),
      render: ({ amount }) => `€${amount.toFixed(2)}`,
    },
  ];

  const renderAddress = (address) => {
    if (!address) return null;
    return `${_.get(address, 'street', '')}${address.unit ? ` ${address.unit}` : ''} ${_.get(address, 'district', '')}, ${_.get(address, 'city', '')} ${_.get(address, 'zip', '')}`;
  };

  const getClientBillingAddress = (client) => {
    const clientAddresses = _.get(client, 'addresses', []);
    if (_.isEmpty(clientAddresses)) return '';
    const address = clientAddresses.find(a => a.isBilling) || clientAddresses[0];
    return address;
  };

  const onCancel = () => {
    setCancelling(true);
    dispatch(cancelPayment(id))
      .then(() => history.push("/payments"))
      .catch(() => setCancelling(false));
  };

  const onForward = async () => {
    await forwardForm.validateFields();
    const { emails } = forwardForm.getFieldsValue();
    setForwarding(true);
    dispatch(forwardPayment(id, emails))
      .finally(() => {
        setForwarding(false);
        onCancelModal();
      });
  };

  const onCancelModal = () => {
    setForwardModalVisible(false);
    forwardForm.resetFields();
  };

  const generatePublicId = () => {
    dispatch(generatePublicIdPayment(id)).then((res) => setPayment({ ...payment, publicId: res.payload }));
  }

  return (
    <div className="page-wrapper">
      <Modal
        title={intl.formatMessage({ id: 'forward' })}
        visible={forwardModalVisible}
        onOk={onForward}
        onCancel={onCancelModal}
        okText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      >
        <Form form={forwardForm}>
          <Form.Item name="emails" rules={[{ required: true, message: intl.formatMessage({ id: "fieldRequired" }) }]}>
            <Checkbox.Group>
              {payment.client.emails.map(e => (
                <div key={e._id}>
                  <Checkbox value={e.emailAddress}>
                    {e.emailAddress} ({e.emailType})
                  </Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "viewPayment" })}
        extra={[
          !publicPayment && !payment.publicId && <Button
            key="generatePublicId"
            type="primary"
            onClick={generatePublicId}
          >
            {intl.formatMessage({ id: "generatePublicId" })}
          </Button>,
          !publicPayment && payment.publicId && <Button
            key="copyLink"
            type="primary"
            onClick={() => {
              copy(`https://dnacleaning.com.cy/payments/public/${payment.publicId}`);
              dispatch(sendNotification({
                type: 'success',
                message: "copied",
                description: "linkCopiedToClipboard"
              }))
            }}
          >
            {intl.formatMessage({ id: "copyLink" })}
          </Button>,
          !publicPayment && <Button
            key="forward"
            type="primary"
            disabled={forwarding || payment.status === 'cancelled'}
            onClick={() => setForwardModalVisible(true)}
          >
            {intl.formatMessage({ id: "forward" })}
          </Button>,
          <Button
            key="print"
            type="primary"
            onClick={handlePrint}
          >
            {intl.formatMessage({ id: "print" })}
          </Button>,
          !publicPayment && (<Popconfirm
            key="delete"
            title={intl.formatMessage({ id: "cancelConfirmation" })}
            onConfirm={onCancel}
            onCancel={() => null}
            okText={intl.formatMessage({ id: "yes" })}
            cancelText={intl.formatMessage({ id: "no" })}
            disabled={cancelling || (payment.status === 'cancelled')}
          >
            <Button
              key="cancel"
              type="danger"
              disabled={cancelling || (payment.status === 'cancelled')}
            >
              {intl.formatMessage({ id: "cancel" })}
            </Button>
          </Popconfirm>)
        ]}
      />
      <div className="payment" style={{ padding: 32, border: '1px solid #f5f5f5' }} ref={componentRef}>
        <Row style={{ marginBottom: 16 }} gutter={16}>
          <Col span={12}>
            <img
              src={"/images/dna.png"}
              alt="logo"
              style={{ width: 200 }}
            />
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                textAlign: "right",
              }}
            >
              <h1 style={{ fontSize: 32 }}>
                {intl.formatMessage({ id: "paymentReceipt" })}
              </h1>
              <span style={{ fontSize: 16 }}>
                {intl.formatMessage({ id: "paymentNum" })}: {_.get(payment, "_ref")}
              </span>
              <span style={{ fontSize: 16 }}>
                {intl.formatMessage({ id: "amount" })}: ‎€{_.get(payment, "amount", 0).toFixed(2)}
              </span>
              <span style={{ fontSize: 16 }}>
                {intl.formatMessage({ id: "paymentMethod" })}: {intl.formatMessage({ id: payment?.method })}
              </span>
              <span style={{ fontSize: 16 }}>
                {intl.formatMessage({ id: "date" })}:{" "}
                {renderDate(_.get(payment, "transactionDate"))}
              </span>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }} gutter={16}>
          <Col span={12}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              title={
                <h2 style={{ fontSize: 24, marginBottom: 0 }}>
                  {intl.formatMessage({ id: "recipient" })}
                </h2>
              }
              style={{ width: "100%", marginBottom: 16 }}
              bodyStyle={{ display: "flex", flexDirection: "column" }}
            >
              <h3>{config.companyName}</h3>
              <span>
                {intl.formatMessage({ id: "address" })}:{" "}
                {renderAddress(config.address)}
              </span>
              <span>
                {intl.formatMessage({ id: "vat" })}: {config.taxID}
              </span>
              <span>
                {intl.formatMessage({ id: "phone" })}: {config.phone}
              </span>
              <span>
                {intl.formatMessage({ id: "fax" })}: {config.fax}
              </span>
              <span>
                {intl.formatMessage({ id: "email" })}: {config.emailAddress}
              </span>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              title={
                <h2 style={{ fontSize: 24, marginBottom: 0 }}>
                  {intl.formatMessage({ id: "from" })}
                </h2>
              }
              style={{ width: "100%", marginBottom: 16 }}
              bodyStyle={{ display: "flex", flexDirection: "column" }}
            >
              <h3>
                {_.get(payment, "client.companyName")
                  ? _.get(payment, "client.companyName")
                  : `${_.get(payment, "client.firstName")} ${_.get(
                    payment,
                    "client.lastName"
                  )}`}
              </h3>
              <span>
                {intl.formatMessage({ id: "address" })}:{" "}
                {renderAddress(getClientBillingAddress(payment.client))}
              </span>
              <span>
                {intl.formatMessage({ id: "name" })}:{" "}
                {_.get(payment, "client.firstName")}{" "}
                {_.get(payment, "client.lastName")}
              </span>
              <span>
                {intl.formatMessage({ id: "phone" })}:{" "}
                {_.get(payment, "client.phones", [])
                  .filter((p) => p.phoneType !== "Fax")
                  .map((p) => p.phoneNumber)
                  .join(", ")}
              </span>
              <span>
                {intl.formatMessage({ id: "fax" })}:{" "}
                {_.get(payment, "client.phones", [])
                  .filter((p) => p.phoneType === "Fax")
                  .map((p) => p.phoneNumber)
                  .join(", ")}
              </span>
              <span>
                {intl.formatMessage({ id: "email" })}:{" "}
                {_.get(payment, "client.emails", [])
                  .map((e) => e.emailAddress)
                  .join(", ")}
              </span>
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              title={intl.formatMessage({ id: "invoices" })}
              style={{ width: "100%", marginBottom: 16 }}
              bodyStyle={{ padding: 0, overflowX: 'scroll' }}
            >
              <Table
                bordered={true}
                columns={columns}
                dataSource={[payment]}
                rowKey={(record) => record._id}
                pagination={false}
              ></Table>
            </Card>
          </Col>
          <Col span={24}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              title={intl.formatMessage({ id: "notes" })}
              style={{ width: "100%", marginBottom: 16 }}
            >
              {_.get(payment, "notes", "")}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
