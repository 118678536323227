import React, { useState } from "react";
import { Button, Form, PageHeader } from "antd";
import { useIntl } from "react-intl";

import ClientForm from "../ClientForm";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createClient } from "../../../redux/actions";

export default (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      setLoading(true);
      dispatch(createClient(values))
        .then(({ payload }) => history.push(`/clients/${payload._id}`))
        .catch(() => setLoading(false));
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "createClient" })}
        extra={[
          <Button disabled={loading} key="create" type="primary" onClick={onSubmit}>
            {intl.formatMessage({ id: "create" })}
          </Button>,
        ]}
      />
      <ClientForm form={form} />
    </div>
  );
};
