import React from "react";

import { Button, DatePicker, Form, Select } from "antd";
import { useIntl } from "react-intl";

const { Option } = Select;
const { RangePicker } = DatePicker;

const AccountantBalanceSearchForm = ({ form, initialFilters, loadData }) => {
  const intl = useIntl();

  const requiredRule = {
    required: true,
    message: intl.formatMessage({ id: "fieldRequired" }),
  };

  return (
    <Form layout="inline" initialValues={initialFilters} form={form} style={{ marginBottom: 24 }}>
      <Form.Item
        label={intl.formatMessage({ id: "fromTo" })}
        name="fromTo"
        required={[requiredRule]}
      >
        <RangePicker format="DD/MM/YYYY" allowClear style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={() => loadData()}>
          {intl.formatMessage({ id: "submit" })}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default AccountantBalanceSearchForm;
