import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, PageHeader } from "antd";

import ClientsTable from './ClientsTable';
import { searchClients } from "../../redux/actions";

export default ({ onRowSelection, hideHeader = false }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState({ data: [], total: 0 });

  const fetchData = (page, size, filters = {}, sort = {}) => {
    return dispatch(searchClients(page, size, filters, sort))
      .then(({ payload }) => setData(payload));
  };

  return (
    <div className="page-wrapper">
      {!hideHeader && (<PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "clients" })}
        extra={[
          <Button
            key="create"
            type="primary"
            onClick={() => history.push("/clients/create")}
          >
            {intl.formatMessage({ id: "create" })}
          </Button>,
        ]}
      />)}
      <ClientsTable
        fetchData={fetchData}
        data={data}
        onRowSelection={onRowSelection}
      />
    </div>
  );
};
