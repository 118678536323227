import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { Button, Card, DatePicker, Form, PageHeader } from "antd";
import moment from "moment";

import { fetchImportedExportedItemsReport, fetchInItemsReport, searchStorage } from "../../redux/actions";
import StorageTable from "./StorageTable";

export default ({ fetchData, data, showReportData = true }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [defaultData, setDefaultData] = useState({ data: [], total: 0 });
  const [imported, setImported] = useState(0);
  const [exported, setExported] = useState(0);
  const [totalIn, setTotalIn] = useState(0);
  const [filter, setFilter] = useState({
    from: moment().subtract(1, 'years'),
    to: moment(),
  });

  const defaultFetchData = (page, size, filters = {}, sort = {}) => {
    return dispatch(searchStorage(page, size, filters, sort))
      .then(({ payload }) => setDefaultData(payload));
  };

  useEffect(() => {
    if (!showReportData) return;
    dispatch(fetchImportedExportedItemsReport(filter))
      .then(({ payload }) => {
        setImported(payload.imported);
        setExported(payload.exported);
      });
    dispatch(fetchInItemsReport())
      .then(({ payload }) => setTotalIn(payload.count));
  }, [filter]);

  const formItemConfig = {
    rules: [
      {
        type: "object",
        required: true,
        message: intl.formatMessage({ id: "pleaseSelectDate" }),
      },
    ],
  };

  return (
    <div className="page-wrapper">
      {showReportData && (
        <>
          <PageHeader
            className="site-page-header-ghost-wrapper"
            ghost={false}
            title={intl.formatMessage({ id: "storage" })}
            extra={[
              <h3 key="total">{intl.formatMessage({ id: 'total' })}: {totalIn}</h3>,
            ]}
          />
          <Card size="small" title={intl.formatMessage({ id: "importExportReport"})} style={{ marginBottom: 24 }}>
            <Form
              name="filter"
              layout="inline"
              initialValues={filter}
              onFinish={(values) => setFilter(values)}
              style={{ marginBottom: 24 }}
            >
              <Form.Item
                name="from"
                label={intl.formatMessage({ id: "from" })}
                {...formItemConfig}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                name="to"
                label={intl.formatMessage({ id: "to" })}
                {...formItemConfig}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {intl.formatMessage({ id: "submit" })}
                </Button>
              </Form.Item>
            </Form>
            <div>
              <span key="imported" style={{ fontSize: 16, marginRight: 24, fontWeight: 600 }}>{intl.formatMessage({ id: 'imported' })}: {imported}</span>
              <span key="exported" style={{ fontSize: 16, fontWeight: 600 }}>{intl.formatMessage({ id: 'exported' })}: {exported}</span>
            </div>
          </Card>
        </>
      )}
      <StorageTable fetchData={fetchData || defaultFetchData} data={data || defaultData} />
    </div>
  );
};
