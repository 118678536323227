import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Alert, Button, Card, Checkbox, Col, DatePicker, Form, PageHeader, Row, Spin, Table } from "antd";
import { useIntl } from "react-intl";
import { useParams, Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import _ from "lodash";
import moment from 'moment';

import { fetchStatement, forwardStatement } from "../../../redux/actions";
import Modal from "antd/lib/modal/Modal";

const { RangePicker } = DatePicker;

export default ({ publicStatement = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [forwarding, setForwarding] = useState(false);
  const [forwardModalVisible, setForwardModalVisible] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [payments, setPayments] = useState([]);
  const [client, setClient] = useState();
  const [data, setData] = useState({ items: [], oldBalance: 0 });
  const [forwardForm] = Form.useForm();
  const [dates, setDates] = useState([]);

  const config = useSelector((state) => state.config, shallowEqual);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({ content: () => componentRef.current });

  const renderDate = (date) => moment(date).format('DD/MM/YYYY');

  const renderAmount = (value) => _.round(value, 2).toFixed(2)

  useEffect(() => {
    dispatch(fetchStatement(id))
      .then(({ payload }) => {
        const { client, invoices, payments } = payload;
        setClient(client);
        setInvoices(invoices);
        setPayments(payments);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const items = [];
    let oldBalance = 0;
    invoices.map((i) => {
      i.type = 'invoice';
      i.date = moment(i.date);
      items.push(i);
    });
    payments.map((i) => {
      i.type = 'payment';
      i.date = moment(i.transactionDate);
      items.push(i);
    });
    let balance = 0;
    if (!_.isEmpty(dates)) {
      const from = parseInt(moment(dates[0]).format('YYYYMMDD'));
      const to = parseInt(moment(dates[1]).format('YYYYMMDD'));
      items.filter((i) => parseInt(i.date.format('YYYYMMDD')) < from).map((i) => {
        i.type === 'invoice' ? oldBalance += i.breakdown.total : oldBalance -= i.amount;
      });
      //  Find items between period
      const selectedPeriodItems = items.filter((i) => parseInt(i.date.format('YYYYMMDD')) >= from && parseInt(i.date.format('YYYYMMDD')) <= to).map((i) => {
        i.type === 'invoice' ? balance += i.breakdown.total : balance -= i.amount;
        return i;
      }).sort((b, a) => parseInt(b.date.format('YYYYMMDD')) - parseInt(a.date.format('YYYYMMDD')));
      setData({ items: selectedPeriodItems, oldBalance });
    } else {
      const newData = items.sort((b, a) => new Date(b.date) - new Date(a.date));
      newData.map((d) => {
        d.type === 'invoice' ? balance += d.breakdown.total : balance -= d.amount;
        d.balance = balance;
      });
      setData({ items: newData, oldBalance });
    }
    setLoading(false);
  }, [invoices, payments, dates]);

  if (loading) return <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} size="large" />;
  
  if (_.isEmpty(client)) return 'Not valid';

  const columns = [
    {
      title: intl.formatMessage({ id: "date" }),
      render: ({ date }) => renderDate(date)
    },
    {
      title: intl.formatMessage({ id: "description" }),
      render: (data) =>
        data.type === "payment" ? (
          <span>
            Payment for Invoice{" "}
            <Link to={`/invoices/${data.invoice._id}`}>{`#${data.invoice._ref}`}</Link>
          </span>
        ) : (
          <span>
            Invoice <Link to={`/invoices/${data._id}`}>{`#${data._ref}`}</Link>
          </span>
        ),
    },
    {
      title: intl.formatMessage({ id: "transactionMethod" }),
      render: ({ method }) => method ? intl.formatMessage({ id: method }) : null
    },
    {
      title: intl.formatMessage({ id: "debit" }),
      render: ({ type, breakdown }) => type === 'invoice' ? `€${renderAmount(breakdown.total)}` || 0 : ''
    },
    {
      title: intl.formatMessage({ id: "credit" }),
      render: ({ type, amount }) => type === 'payment' ? `€${renderAmount(amount)}` : ''
    },
    {
      title: intl.formatMessage({ id: "balance" }),
      render: ({ balance }) => `€${renderAmount(balance)}`
    },
  ];

  const renderAddress = (address) => {
    if (!address) return null;
    return `${address.street}${address.unit ? ` ${address.unit}` : ''}, ${address.city} ${_.get(address, "zip", "")}`;
  };

  const getClientBillingAddress = (client) => {
    const clientAddresses = _.get(client, 'addresses', []);
    const billingAddress = clientAddresses.find(a => a.isBilling);
    return billingAddress || clientAddresses[0];
  };

  const onForward = async () => {
    await forwardForm.validateFields();
    const { emails } = forwardForm.getFieldsValue();
    setForwarding(true);
    dispatch(forwardStatement(client._id, emails))
      .finally(() => {
        setForwarding(false);
        onCancelModal();
      });
  };

  const onCancelModal = () => {
    setForwardModalVisible(false);
    forwardForm.resetFields();
  };

  const renderStatementDates = () => {
    if (_.isEmpty(dates)) return renderDate(Date.now());
    return `${dates[0].format('DD/MM/YYYY')} - ${dates[1].format('DD/MM/YYYY')}`;
  }

  return (
    <div className="page-wrapper">
      <Modal
        title={intl.formatMessage({ id: 'forward' })}
        visible={forwardModalVisible}
        onOk={onForward}
        onCancel={onCancelModal}
        okText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      >
        <Form form={forwardForm}>
          <Form.Item name="emails" rules={[{ required: true, message: intl.formatMessage({ id: "fieldRequired" }) }]}>
            <Checkbox.Group>
              {_.get(client,'emails', []).map(e => (
                <div key={e._id}>
                  <Checkbox value={e.emailAddress}>
                    {e.emailAddress} ({e.emailType})
                  </Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "statement" })}
        extra={[
          !publicStatement && <Button
            key="forward"
            type="primary"
            disabled={forwarding}
            onClick={() => setForwardModalVisible(true)}
          >
            {intl.formatMessage({ id: "forward" })}
          </Button>,
          <Button
            key="print"
            type="primary"
            onClick={handlePrint}
          >
            {intl.formatMessage({ id: "print" })}
          </Button>,
          <RangePicker format="DD/MM/YYYY" key="dates" onChange={(v) => setDates(v)} />
        ]}
      />
      <div className="statement" style={{ padding: 32, border: '1px solid #f5f5f5' }} ref={componentRef}>
        <Row style={{ marginBottom: 16 }} gutter={16}>
          <Col span={12}>
            <img
              src={"/images/dna.png"}
              alt="logo"
              style={{ width: 200 }}
            />
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                textAlign: "right",
              }}
            >
              <h1 style={{ fontSize: 32 }}>
                {intl.formatMessage({ id: "statement" })}
              </h1>
              <span style={{ fontSize: 16 }}>
                {intl.formatMessage({ id: "date" })}:{" "}
                {renderStatementDates()}
              </span>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }} gutter={16}>
          <Col span={12}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              title={
                <h2 style={{ fontSize: 24, marginBottom: 0 }}>
                  {intl.formatMessage({ id: "from" })}
                </h2>
              }
              style={{ width: "100%", marginBottom: 16 }}
              bodyStyle={{ display: "flex", flexDirection: "column" }}
            >
              <h3>{config.companyName}</h3>
              <span>
                {intl.formatMessage({ id: "address" })}:{" "}
                {renderAddress(config.address)}
              </span>
              <span>
                {intl.formatMessage({ id: "vat" })}: {config.taxID}
              </span>
              <span>
                {intl.formatMessage({ id: "phone" })}: {config.phone}
              </span>
              <span>
                {intl.formatMessage({ id: "fax" })}: {config.fax}
              </span>
              <span>
                {intl.formatMessage({ id: "email" })}: {config.emailAddress}
              </span>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              title={
                <h2 style={{ fontSize: 24, marginBottom: 0 }}>
                  {intl.formatMessage({ id: "client" })}
                </h2>
              }
              style={{ width: "100%", marginBottom: 16 }}
              bodyStyle={{ display: "flex", flexDirection: "column" }}
            >
              <h3>{client.companyName || `${client.firstName} ${client.lastName}`}</h3>
              <span>
                {intl.formatMessage({ id: "address" })}:{" "}
                {renderAddress(getClientBillingAddress(client))}
              </span>
              <span>
                {intl.formatMessage({ id: "name" })}: {` ${_.get(client, "firstName", "")} ${_.get(client, "lastName", "")}`}
              </span>
              <span>
                {intl.formatMessage({ id: "phone" })}:{" "}
                {_.get(client, "phones", [])
                  .filter((p) => p.phoneType !== "Fax")
                  .map((p) => p.phoneNumber)
                  .join(", ")}
              </span>
              <span>
                {intl.formatMessage({ id: "fax" })}:{" "}
                {_.get(client, "phones", [])
                  .filter((p) => p.phoneType === "Fax")
                  .map((p) => p.phoneNumber)
                  .join(", ")}
              </span>
              <span>
                {intl.formatMessage({ id: "email" })}:{" "}
                {_.get(client, "emails", [])
                  .map((e) => e.emailAddress)
                  .join(", ")}
              </span>
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              style={{ width: "100%", marginBottom: 16 }}
              bodyStyle={{ padding: 0, overflowX: 'scroll' }}
            >
              {data.oldBalance !== 0 && (<Alert type="info" message={`${intl.formatMessage({ id: "oldBalance" })}: €${renderAmount(data.oldBalance)}`} style={{ textAlign: "right", fontWeight: "bold" }} />)}
              <div className="print-table-small">
                <Table
                  size="small"
                  bordered={true}
                  columns={columns}
                  dataSource={data.items}
                  rowKey={(record) => record._id}
                  pagination={false}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
