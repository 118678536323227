import * as types from "./types";

const baseRoute = "v1/invoice";

export const searchInvoices = (page, size, filters = {}, sort = {}) => {
  return (api) => ({
    type: types.SEARCH_INVOICES,
    payload: api.post(`${baseRoute}/search`, { page, size, filters, sort }),
  });
};

export const fetchInvoice = (id) => {
  return (api) => ({
    type: types.FETCH_INVOICE,
    payload: api.get(`${baseRoute}/${id}`),
    showUserErrors: true
  });
};

export const fetchPublicInvoice = (id) => {
  return (api) => ({
    type: types.FETCH_PUBLIC_INVOICE,
    payload: api.get(`${baseRoute}/public/${id}`),
    showUserErrors: true
  });
};

export const forwardInvoice = (id, emails) => {
  return (api) => ({
    type: types.FORWARD_INVOICE,
    payload: api.post(`${baseRoute}/forward/${id}`, { emails }),
    showUserErrors: true,
    success: {
      message: 'forwardInvoice',
      description: 'forwardInvoiceSuccess'
    }
  });
};

export const generatePublicIdInvoice = (id) => {
  return (api) => ({
    type: types.GENERATE_PUBLIC_ID_INVOICE,
    payload: api.post(`${baseRoute}/generatePublicId/${id}`),
    showUserErrors: true,
  });
};

export const cancelInvoice = (id) => {
  return (api) => ({
    type: types.CANCEL_INVOICE,
    payload: api.put(`${baseRoute}/cancel/${id}`),
    success: {
      message: "cancelInvoice",
      description: "cancelSuccess",
    },
  });
};

export const createInvoice = (data) => {
  return (api) => ({
    type: types.CREATE_INVOICE,
    payload: api.post(baseRoute, data),
    success: {
      message: "createInvoice",
      description: "createSuccess",
    },
  });
};

export const updateInvoice = (id, data) => {
  return (api) => ({
    type: types.UPDATE_INVOICE,
    payload: api.put(`${baseRoute}/${id}`, data),
    success: {
      message: 'updateInvoice',
      description: 'updateSuccess'
    }
  });
};

export const deleteInvoice = (id) => {
  return (api) => ({
    type: types.DELETE_INVOICE,
    payload: api.delete(`${baseRoute}/${id}`),
    id,
    success: {
      message: 'deleteInvoice',
      description: 'deleteSuccess'
    }
  });
};

export const deleteJobFromInvoice = (id, jobId) => { 
  return (api) => ({ 
    type: types.DELETE_JOB_FROM_INVOICE, 
    payload: api.delete(`${baseRoute}/${id}/job/${jobId}`), 
    success: { 
      message: "deleteJobFromInvoice", 
      description: "deleteSuccess", 
    }, 
  }) 
} 

export const fetchClientBalance = (id) => {
  return (api) => ({
    type: types.FETCH_CLIENT_BALANCE,
    payload: api.get(`${baseRoute}/client_balance/${id}`),
  });
};

export const issueSubscriptionInvoice = (client) => {
  return (api) => ({
    type: types.ISSUE_SUBSCRIPTION_INVOICE,
    payload: api.post(`${baseRoute}/issue-subscription-invoice`, { client }),
    success: {
      message: 'issueSubscriptionInvoice',
      description: 'issueSubscriptionInvoiceSuccess'
    },
    showUserErrors: true
  });
};
