import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import { CSVLink } from "react-csv";
import _ from "lodash";
import { useIntl } from "react-intl";

export default ({
  fetchData,
  filters = {},
  columns,
  data,
  onRowSelection,
  rowSelectionType = "radio",
  rowKey = (record) => record._id,
}) => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({ searchText: "", searchedColumn: "" });
  const [csvData, setCSVData] = useState([]);
  const intl = useIntl();

  const loadData = (current = 1, pageSize = 10, filters = {}, sort = {}) => {
    if (!fetchData) return;
    setLoading(true);
    return fetchData(current, pageSize, filters, sort)
      .then(() => setPagination({ current, pageSize }))
      .finally(() => setLoading(false));
  };
  
  useEffect(() => {
    loadData(1, 10, filters);
  }, []);

  useEffect(() => {
    let newData = _.cloneDeep(data);
    newData = _.get(newData, "data", [])
      .map((i) => {
        if (!_.isEmpty(i.invoice)) {
          i.invoice = i.invoice[0]._ref;
        }
        if (i.breakdown) {
          i.taxValue = _.get(i, 'breakdown.taxValue');
          i.taxTotal = _.get(i, 'breakdown.taxTotal');
          i.total = _.get(i, 'breakdown.total');
          i.netTotal = _.get(i, 'breakdown.netTotal');
        }
        return _.omit(i, ["breakdown", "client", "_id", "job", "clientRef", "jobs", "__v", "items", "addresses", "subscriptionInvoice"]);
      }
    )
    setCSVData(newData);
  }, [data]);

  const handleTableChange = (pagination, columnFilters, sorter) => {
    let filtering = {};
    const sorting = {};
    if (_.get(sorter, "order")) {
      sorting[sorter.field] = sorter.order === "ascend" ? 1 : -1;
    }
    Object.entries(columnFilters).forEach(
      ([key, value]) =>
        value && (filtering[key] = { $regex: value[0], $options: "i" })
    );
    filtering = { ...filtering, ...filters };
    const { current, pageSize } = pagination;
    loadData(current, pageSize, filtering, sorting);
  };

  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },

    render: (text) =>
      search.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[search.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearch({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearch({
      ...search,
      searchText: "",
    });
  };

  return (
    <div style={{ overflowX: "scroll" }}>
      <Table
        bordered={true}
        columns={_.cloneDeep(columns).map((c, i) => {
          let formattedColumn = c.dataIndex ? {...c, ...getColumnSearchProps(c.dataIndex) } : c;
          if (i === 0) {
            const title = formattedColumn.title;
            formattedColumn.title = () => <div style={{ display: 'flex', flexDirection: 'column' }}><div>{title}</div><CSVLink data={csvData}><Button type="link" style={{ padding: 0 }}>{intl.formatMessage({ id: 'download' })}</Button></CSVLink></div>;
          }
          return formattedColumn;
        })}
        dataSource={data.data}
        loading={loading}
        rowKey={rowKey}
        rowSelection={
          onRowSelection && {
            type: rowSelectionType,
            onChange: onRowSelection,
          }
        }
        size="small"
        pagination={{ ...pagination, total: data.total }}
        onChange={handleTableChange}
      />
    </div>
  );
};
