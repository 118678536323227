import React, { useState } from "react";
import { Button, Form, PageHeader } from "antd";
import { useIntl } from "react-intl";

import ClientItemForm from "../ClientItemForm";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createClientItem } from "../../../redux/actions";

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    setLoading(true);
    dispatch(createClientItem(values))
      .then(({ payload }) => history.push(`/clientItems/${payload._id}`))
      .catch(() => setLoading(false));
  };

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "createClientItem" })}
        extra={[
          <Button
            disabled={loading}
            key="create"
            type="primary"
            onClick={onSubmit}
          >
            {intl.formatMessage({ id: "create" })}
          </Button>,
        ]}
      />
      <ClientItemForm form={form} />
    </div>
  );
};
