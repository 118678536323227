import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, Typography } from "antd";
import moment from 'moment';
import _ from 'lodash';

import ArchiveTable from "../../components/ArchiveTable";

const { Paragraph } = Typography;

const dateRenderer = (date) => {
  if (!date) return;
  return moment(date).format('DD/MM/YYYY');
}

export default ({ fetchData, data }) => {
  const intl = useIntl();
  const history = useHistory();

  const columns = [
    {
      title: intl.formatMessage({ id: "actions" }),
      render: (record) => (
        <div>
          <Button
            type="link"
            onClick={() => history.push(`/payments/${record._id}`)}
          >
            {intl.formatMessage({ id: "view" })}
          </Button>
          <Button
            type="link"
            onClick={() => history.push(`/payments/edit/${record._id}`)}
          >
            {intl.formatMessage({ id: "edit" })}
          </Button>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: "client" }),
      render: (record) => (
        <Button
          type="link"
          onClick={() => history.push(`/clients/${_.get(record, 'client._id')}`)}
          style={{ marginRight: 8 }}
        >
        #{_.get(record, 'client._ref')} {_.get(record, 'client.firstName')} {_.get(record, 'client.lastName')} {_.get(record, 'clientCompanyName') ? `[${_.get(record, 'clientCompanyName')}]` : ''}
        </Button>
      ),
    },
    {
      title: intl.formatMessage({ id: "ref" }),
      render: (record) => record._ref || "-"
    },
    {
      title: intl.formatMessage({ id: "amount" }),
      render: (record) => `€${_.get(record, 'amount',0).toFixed(2)}`
    },
    {
      title: intl.formatMessage({ id: "transactionDate" }),
      render: (record) => dateRenderer(record.transactionDate)
    },
    {
      title: intl.formatMessage({ id: "method" }),
      render: (record) => intl.formatMessage({ id: record.method })
    },
    {
      title: intl.formatMessage({ id: 'invoice' }),
      render: (record) => _.isEmpty(record.invoice) ? null : (
        <Button
          type="link"
          onClick={() => history.push(`/invoices/${_.get(record, 'invoice.0._id')}`)}
        >
          {_.get(record, 'invoice.0._ref')}
        </Button>
      ),
    },
    {
      title: intl.formatMessage({ id: "lastSent" }),
      render: (record) => dateRenderer(record.lastSent)
    },
    {
      title: intl.formatMessage({ id: "notes" }),
      render: (record) => record.notes ? <Paragraph ellipsis={{ rows: 1 }}>{record.notes}</Paragraph> : null
    },
  ];

  return (
    <ArchiveTable
      fetchData={fetchData}
      columns={columns}
      data={data}
    />
  );
};
