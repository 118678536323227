import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, PageHeader, Popconfirm, Spin, } from "antd";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { cancelPayment, fetchPayment, updatePayment } from "../../../redux/actions";
import moment from "moment";
import _ from "lodash";
import PaymentForm from "../PaymentForm";

export default (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchPayment(id))
      .then(({ payload }) => setData(payload))
      .then(() => setLoading(false));
  }, []);

  const onSave = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    setSubmitting(true);
    dispatch(updatePayment(id, _.pick(values, ["amount", "notes"])))
      .then(() => history.push("/payments"))
      .catch(() => setSubmitting(false));
  };

  const onDelete = () => {
    setSubmitting(true);
    dispatch(cancelPayment(id))
      .then(() => history.push("/payments"))
      .catch(() => setSubmitting(false));
  };

  if (loading) {
    return <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} size="large" />
  }

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "editPayment" })}
        extra={[
          <Button
            key="edit"
            type="primary"
            onClick={onSave}
            loading={submitting}
          >
            {intl.formatMessage({ id: "save" })}
          </Button>,
          <Popconfirm
            title={intl.formatMessage({ id: "deleteConfirmation" })}
            onConfirm={onDelete}
            disabled={submitting}
            onCancel={() => null}
            okText={intl.formatMessage({ id: "yes" })}
            cancelText={intl.formatMessage({ id: "no" })}
          >
            <Button disabled={submitting} key="delete" type="primary" danger>
              {intl.formatMessage({ id: "delete" })}
            </Button>
          </Popconfirm>,
        ]}
      />
      <PaymentForm
        invoice={data.invoice}
        defaultValues={{
          ...data,
          transactionDate: data.transactionDate && moment(data.transactionDate),
        }}
        form={form}
      />
    </div>
  );
};
