import React, { useEffect, useState } from "react";
import { Button, Form, PageHeader, Popconfirm, Spin } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import _ from 'lodash';

import ClientForm from "../ClientForm";
import { deleteClient, fetchClient, updateClient } from "../../../redux/actions";

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);

  const { id } = useParams();
  const [form] = Form.useForm();

  const data = useSelector((state) => state.clients.data[id], shallowEqual);

  useEffect(() => {
    dispatch(fetchClient(id));
  }, []);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const onSave = () => {
    const data = form.getFieldsValue();
    setSubmitting(true);
    dispatch(updateClient(id, data))
      .then(() => history.push(`/clients/${id}`))
      .catch(() => setSubmitting(false));
  };

  const onDelete = () => {
    setSubmitting(true);
    dispatch(deleteClient(id))
      .then(() => history.push("/clients"))
      .catch(() => setSubmitting(false));
  };

  if (_.isEmpty(data)) {
    return <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} size="large" />
  }

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "editClient" })}
        extra={[
          <Button disabled={submitting} key="save" type="primary" onClick={onSave}>
            {intl.formatMessage({ id: "save" })}
          </Button>,
          <Popconfirm
            key="delete"
            title={intl.formatMessage({ id: 'deleteConfirmation' })}
            onConfirm={onDelete}
            disabled={submitting}
            onCancel={() => null}
            okText={intl.formatMessage({ id: 'yes' })}
            cancelText={intl.formatMessage({ id: 'no' })}
          >
            <Button disabled={submitting}  type="primary" danger>
              {intl.formatMessage({ id: "delete" })}
            </Button>
          </Popconfirm>,
        ]}
      />
      <ClientForm data={data} form={form} />
    </div>
  );
};
