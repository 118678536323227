import React, { useEffect, useState } from "react";
import { Button, Form, PageHeader, Popconfirm, Spin } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import _ from 'lodash';

import ItemForm from "../ItemForm";
import { deleteItem, fetchItem, updateItem } from "../../../redux/actions";

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);

  const { id } = useParams();
  const [form] = Form.useForm();

  const data = useSelector((state) => state.items.data[id], shallowEqual);

  useEffect(() => {
    dispatch(fetchItem(id));
  }, []);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const onSave = () => {
    const data = form.getFieldsValue();
    setSubmitting(true);
    dispatch(updateItem(id, data))
      .then(() => history.push(`/items/${id}`))
      .catch(() => setSubmitting(false));
  };

  const onDelete = () => {
    setSubmitting(true);
    dispatch(deleteItem(id))
      .then(() => history.push("/items"))
      .catch(() => setSubmitting(false));
  };

  if (_.isEmpty(data)) {
    return <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} size="large" />
  }

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "editItem" })}
        extra={[
          <Button disabled={submitting} key="save" type="primary" onClick={onSave}>
            {intl.formatMessage({ id: "save" })}
          </Button>,
          <Popconfirm
            title={intl.formatMessage({ id: 'deleteConfirmation' })}
            onConfirm={onDelete}
            disabled={submitting}
            onCancel={() => null}
            okText={intl.formatMessage({ id: 'yes' })}
            cancelText={intl.formatMessage({ id: 'no' })}
            key="delete"
          >
            <Button disabled={submitting} key="delete" type="primary" danger>
              {intl.formatMessage({ id: "delete" })}
            </Button>
          </Popconfirm>,
        ]}
      />
      <ItemForm data={data} form={form} />
    </div>
  );
};
