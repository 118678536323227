import React, { useState } from 'react';
import { Button, Card, Col, Form, Input, InputNumber, PageHeader, Row, Select } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { updateConfig } from "../../redux/actions";
import EditableTagGroup from "./EditableTagGroup";

export default () => {

  const dispatch = useDispatch();
  const intl = useIntl();

  const [submitting, setSubmitting] = useState(false);

  const config = useSelector(state => state.config, shallowEqual);

  const requiredRule = { required: true, message: intl.formatMessage({ id: 'required' }) };
  const emailRule = { type: 'email', message: intl.formatMessage({ id: 'invalidValue' }) };
  const urlRule = { type: 'url', message: intl.formatMessage({ id: 'invalidValue' }) };

  const onFinish = (values) => {
    setSubmitting(true);
    dispatch(updateConfig(values))
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "setup" })}
      />
      <Form name="setup" layout="vertical" initialValues={config} onFinish={onFinish}>
        <Form.Item name="companyName" label={intl.formatMessage({ id: "companyName" })} rules={[requiredRule]}>
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col>
            <Form.Item name={["address", "street"]} label={intl.formatMessage({ id: "addressStreet" })} rules={[requiredRule]}>
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name={["address", "city"]} label={intl.formatMessage({ id: "addressCity" })} rules={[requiredRule]}>
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name={["address", "zip"]} label={intl.formatMessage({ id: "addressZip" })} rules={[requiredRule]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="emailAddress" label={intl.formatMessage({ id: "emailAddress" })} rules={[requiredRule, emailRule]}>
          <Input />
        </Form.Item>
        <Form.Item name="logo" label={intl.formatMessage({ id: "logo" })} rules={[requiredRule, urlRule]}>
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="phone" label={intl.formatMessage({ id: "phone" })} rules={[requiredRule]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="fax" label={intl.formatMessage({ id: "fax" })}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="taxID" label={intl.formatMessage({ id: "taxID" })} rules={[requiredRule]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="taxValue" label={intl.formatMessage({ id: "taxValue" })} rules={[requiredRule]}>
              <InputNumber min={0} max={100} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="iban" label={intl.formatMessage({ id: "iban" })}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="bankAccountNumber" label={intl.formatMessage({ id: "bankAccountNumber" })}>
              <Input.TextArea rows={1} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="bankDetails" label={intl.formatMessage({ id: "bankDetails" })}>
              <Input.TextArea rows={1} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="websiteUrl" label={intl.formatMessage({ id: "website" })} rules={[urlRule]}>
          <Input />
        </Form.Item>
        <Form.Item name="subscriptionDate" label={intl.formatMessage({ id: "subscriptionDate" })} rules={[requiredRule]}>
          <InputNumber min={1} max={28} />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'clientItemsCategories' })} name="clientItemsCategories" rules={[requiredRule]}>
          <EditableTagGroup />
        </Form.Item>
        <Form.List name="priceCalculator">
          {(fields, { add, remove }) => {
            return (
              <Card
                title={intl.formatMessage({ id: "priceCalculator" })}
                extra={
                  <Button type="dashed" onClick={() => { add({}); }}>
                    <PlusOutlined /> {intl.formatMessage({ id: "add" })}
                  </Button>
                }
                bodyStyle={{ padding: "0px 8px" }}
                bordered={false}
                style={{ margin: "8px 0px" }}
              >
                {fields.map((field, index) => (
                <Row
                  gutter="16"
                  key={`items-${index}`}
                  style={{ border: "1px dashed #d9d9d9", padding: 8 }}
                >
                  <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
                    <Form.Item
                      {...field}
                      label={intl.formatMessage({ id: "clientItemCategory" })}
                      name={[field.name, "clientItemCategory"]}
                      fieldKey={[field.fieldKey, "clientItemCategory"]}
                      rules={[requiredRule]}
                    >
                      <Select showSearch>
                        {config.clientItemsCategories.map((c) => <Select.Option key={c} value={c}>{intl.formatMessage({ id: c })}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={3} xxl={3}>
                    <Form.Item
                      {...field}
                      label={intl.formatMessage({ id: "algorithm" })}
                      name={[field.name, "algorithm"]}
                      fieldKey={[field.fieldKey, "algorithm"]}
                      rules={[requiredRule]}
                      initialValue={1}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={6} sm={6} md={3} lg={4} xl={4} xxl={4}>
                    <Form.Item
                      label={intl.formatMessage({ id: "remove" })}
                    >
                      <Button
                        type="dashed"
                        danger
                        onClick={() => {
                          remove(field.name);
                        }}
                      >
                        <MinusCircleOutlined />
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              </Card>
            )}}
        </Form.List>
        <Form.Item>
          <Button disabled={submitting} type="primary" htmlType="submit">
            {intl.formatMessage({ id: "submit" })}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
