import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, Popconfirm } from "antd";
import moment from 'moment';
import _ from 'lodash';

import ArchiveTable from "../../components/ArchiveTable";

const dateRenderer = (date) => {
  if (!date) return;
  return moment(date).format('DD/MM/YYYY');
}

export default ({ fetchData, data, onRowSelection, rowSelectionType, onRemoveJob }) => {
  const intl = useIntl();
  const history = useHistory();

  const columns = [
    {
      title: intl.formatMessage({ id: "actions" }),
      className: "actions-column",
      render: (record) => (
        <div>
          <Button
            type="link"
            onClick={() => history.push(`/jobs/${record._id}`)}
            style={{ marginRight: 8 }}
          >
            {intl.formatMessage({ id: "view" })}
          </Button>
          <Button
            type="link"
            onClick={() => history.push(`/jobs/edit/${record._id}`)}
          >
            {intl.formatMessage({ id: "edit" })}
          </Button>
          {onRemoveJob && (
            <Popconfirm
              cancelText={intl.formatMessage({ id: "no" })}
              okText={intl.formatMessage({ id: "yes" })}
              onConfirm={() => onRemoveJob(record._id)}
              title={intl.formatMessage({ id: "confirmDelete" })}
            >
              <Button type="link" danger>
                {intl.formatMessage({ id: "delete" })}
              </Button>
            </Popconfirm>
          )}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: "client" }),
      render: (record) => _.get(record, 'client') ? (
        <Button
          type="link"
          onClick={() => history.push(`/clients/${_.get(record, 'client._id')}`)}
          style={{ marginRight: 8 }}
        >
        #{_.get(record, 'client._ref')} {_.get(record, 'client.firstName')} {_.get(record, 'client.lastName')} {_.get(record, 'clientCompanyName') ? `[${_.get(record, 'clientCompanyName')}]` : ''}
        </Button>
      ) : null,
    },
    {
      title: intl.formatMessage({ id: "startDate" }),
      render: (record) => dateRenderer(record.startDate)
    },
    {
      title: intl.formatMessage({ id: "dueDate" }),
      render: (record) => dateRenderer(record.dueDate)
    },
    {
      title: intl.formatMessage({ id: "endDate" }),
      render: (record) => dateRenderer(record.endDate)
    },
    {
      title: intl.formatMessage({ id: "total" }),
      sorter: true,
      render: ({ total }) => `€${total.toFixed(2)}`,
    },
    {
      title: intl.formatMessage({ id: "subscription" }),
      render: ({ subscription }) => subscription ? intl.formatMessage({ id: 'yes' }) : intl.formatMessage({ id: 'no' }),
    },
    {
      title: intl.formatMessage({ id: 'invoice' }),
      render: (record) => _.isEmpty(record.invoice) ? null : (
        <Button
          type="link"
          onClick={() => history.push(`/invoices/${_.get(record, 'invoice.0._id')}`)}
        >
          {_.get(record, 'invoice.0._ref')}
        </Button>
      ),
    },
  ];

  return <ArchiveTable fetchData={fetchData} columns={columns} data={data} onRowSelection={onRowSelection} rowSelectionType={rowSelectionType} />;
};
