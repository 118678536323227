import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, Route, withRouter, Switch } from "react-router-dom";
import { Col, ConfigProvider, Layout, Menu, Row, Spin } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import moment from 'moment';
import { FormattedMessage, IntlProvider } from 'react-intl';
import translations from '../../i18n/locales';

import Notification from '../../components/system/Notification';
import Redirect from '../../components/system/Redirect';
import AvatarDropdown from '../../components/system/AvatarDropdown';
import LanguagesDropdown from '../../components/system/LanguagesDropdown';
//  Import moment Languages
import 'moment/locale/en-gb';
import 'moment/locale/el';

import menuItems from '../../config/menuItems';
import MainPageRoutes from './MainPageRoutes';
import RequireAuth from "../../components/system/RequiresAuth";
import * as Pages from "../index";
import { fetchPublicConfig } from "../../redux/actions";
import _ from 'lodash';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const Item = ({to, children, exact = false, ...props}) => (
	<Menu.Item {...props}>
		<NavLink className="nav-text" activeClassName="active" to={to} exact={exact}>
			{children}
		</NavLink>
	</Menu.Item>
);

const renderMenuItemChildren = ({icon, title}) => (
	<>
		{icon}
		<span><FormattedMessage id={title} /></span>
	</>
);

const renderMenuItem = item => {
	return item.submenu ? (
		<SubMenu key={item.path} title={renderMenuItemChildren(item)}>
			{item.submenu.map(renderMenuItem)}
		</SubMenu>
	) : (
		<Item key={item.path} to={item.path} exact={item.exact}>
			{item.children ? item.children : renderMenuItemChildren(item)}
		</Item>
	)
};

const MainPage = (props) => {
  //  Main Menu Location and collapse status
  const { config, location } = props;
  const root = '/' + location.pathname.split('/')[1];
  const [collapsed, setCollapsed] = useState(false);
  const [loadingPublicConfig, setLoadingPublicConfig] = useState(true);

  //  Locale Info
  const [locale, setLocale] = useState(props.language.antd);
  const [intlLocale, setintlLocale] = useState(props.language.id);

  useEffect(() => {
    setLocale(props.language.antd);
    moment.locale(props.language.moment);
    setintlLocale(props.language.id);
  }, [props.language]);

  useEffect(() => {
    if (_.isNil(_.get(props.config, 'logo'))) {
      props.fetchPublicConfig()
        .then(() => setLoadingPublicConfig(false));
    } else {
      setLoadingPublicConfig(false);
    }
  }, []);

  if (loadingPublicConfig) {
    return <div style={{ position: 'fixed', top: '50%', left: '50%' }}><Spin size="large" /></div>
  }

  return (
    <IntlProvider
      locale={intlLocale}
      defaultLocale={intlLocale}
      key={intlLocale}
      messages={translations[intlLocale]}
    >
      <ConfigProvider locale={locale}>
        <Switch>
          <Route exact path="/invoices/public/:id">
            <Pages.InvoicesView publicInvoice={true} />
          </Route>
          <Route exact path="/payments/public/:id">
            <Pages.PaymentsView publicPayment={true} />
          </Route>
          <Route exact path="/clients/public/statement/:id">
            <Pages.ClientsStatement publicStatement={true} />
          </Route>
          <RequireAuth path="/">
            <Layout id="mainPageLayout">
              <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="logo"><img src={config.logo} /></div>
                <Menu
                  id="topMenu"
                  theme="dark"
                  mode="inline"
                  //  defaultSelectedKeys={[root]}
                  defaultOpenKeys={[root]}
                  selectedKeys={[location.pathname]}>
                  {menuItems.sideMenuItems.map(renderMenuItem)}
                </Menu>
              </Sider>
              <Layout>
                <Header id="topHeader">
                  <Row>
                    <Col span={18}>
                      {collapsed
                        ? <MenuUnfoldOutlined className="headerMenu-IconBurger" onClick={() => setCollapsed(!collapsed)} />
                        : <MenuFoldOutlined className="headerMenu-IconBurger" onClick={() => setCollapsed(!collapsed)} />
                      }
                    </Col>
                    <Col span={6}>
                      <div id="topHeaderRightElements" className="right">
                        <AvatarDropdown />
                        <LanguagesDropdown />
                      </div>
                    </Col>
                  </Row>
                </Header>
                <Content id="mainPageContent">
                  <MainPageRoutes />
                </Content>
              </Layout>
            </Layout>
            <Notification />
            <Redirect />
          </RequireAuth>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
};

const mapStateToProps = (state) => {
  return {
    language: state.system.language,
    config: state.config,
  };
};

export default connect(mapStateToProps, { fetchPublicConfig })(withRouter(MainPage));
