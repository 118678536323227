import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, Col, DatePicker, Form, PageHeader, Row, Select, Spin } from "antd";
import { useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import moment from 'moment';
import _ from 'lodash';

import InvoiceTable from "./InvoiceTable";
import { fetchInvoiceAmountPerMonthReport, searchInvoices } from "../../redux/actions";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [data, setData] = useState({ data: [], total: 0 });
  const [loadingReport, setLoadingReport] = useState(true);
  const [reportData, setReportData] = useState({});
  const [csvData, setCSVData] = useState([]);
  const [csvGenerated, setCSVGenerated] = useState(false);
  const csvHeaders = [{
    label: "Αρ. Τιμολογίου",
    key: "_ref"
  }, {
    label: "Ημερομηνία",
    key: "date"
  }, {
    label: "Όνομα Εταιρίας",
    key: "clientCompanyName"
  }, {
    label: "Όνομα Πελάτη",
    key: "clientFullName"
  }, {
    label: "Ποσό πριν το ΦΠΑ",
    key: "netTotal"
  }, {
    label: "ΦΠΑ",
    key: "taxTotal"
  }, {
    label: "Τιμή ΦΠΑ",
    key: "taxValue"
  }, {
    label: "Ολικό",
    key: "total"
  }, {
    label: "Κατάσταση",
    key: "status"
  }];

  const requiredRule = {
    required: true,
    message: intl.formatMessage({ id: "fieldRequired" }),
  };

  const fetchReport = async () => {
    try {
      await form.validateFields();
      setLoadingReport(true);
      const values = form.getFieldsValue();
      const filter = {};
      if (!_.isEmpty(values.fromTo)) {
        filter.from = values.fromTo[0];
        filter.to = values.fromTo[1];
      }
      if (!_.isEmpty(values.invoiceType)) filter.invoiceType = values.invoiceType;
      dispatch(fetchInvoiceAmountPerMonthReport(filter))
        .then(({ payload }) => {
          const chartData = payload.perMonth.sort((a, b) => `${a.year}${a.month}` - `${b.year}${b.month}`).map((m) => ({
            name: `${m.month}/${m.year}`,
            amount: m.amount,
          }));
          setReportData({ ...payload, chartData });
        })
        .then(() => setLoadingReport(false));
    } catch (err) {
      console.error(err);
    }
  }

  const fetchData = (page, size, filters = {}, sort = {}) => {
    setCSVGenerated(false);
    if (filters?.invoiceType) filters.invoiceType = { $in: filters.invoiceType }
    return dispatch(searchInvoices(page, size, filters, sort))
      .then(({ payload }) => setData(payload));
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "invoices" })}
        extra={[
          <Button
            key="create"
            type="primary"
            onClick={() => history.push("/invoices/create")}
          >
            {intl.formatMessage({ id: "create" })}
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            fromTo: [moment().subtract(1, 'years'), moment().add(1, 'year')],
            invoiceType: ["invoice", "other"]
          }}
          onFinish={async () => {
            try {
              await form.validateFields();
              fetchReport();
              const values = form.getFieldsValue();
              const filter = {};
              if (!_.isEmpty(values.invoiceType)) filter.invoiceType = values.invoiceType;
              if (!_.isEmpty(values.fromTo)) filter.fromTo = values.fromTo;
              fetchData(undefined, undefined, filter);
            } catch (err) {
              console.error(err);
            }

          }}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={intl.formatMessage({ id: "fromTo" })} name="fromTo" required={[requiredRule]}>
                <RangePicker format="DD/MM/YYYY" allowClear style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={intl.formatMessage({ id: "invoiceType" })} name="invoiceType" required={[requiredRule]}>
                <Select allowClear mode="multiple" style={{ width: "100%" }}>
                  <Option value="invoice" key="invoice">{intl.formatMessage({ id: "invoice" })}</Option>
                  <Option value="other" key="other">{intl.formatMessage({ id: "other" })}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label=" ">
                <Button block type="primary" htmlType="submit">
                  {intl.formatMessage({ id: "submit" })}
                </Button>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label=" ">
                {csvGenerated ? <CSVLink
                  data={csvData}
                  headers={csvHeaders}
                ><Button block type="primary">{intl.formatMessage({ id: "downloadCSV" })}</Button></CSVLink> : <Button block type="primary" onClick={async (event) => {
                  try {
                    await form.validateFields();
                    const values = form.getFieldsValue();
                    const filter = {};
                    if (!_.isEmpty(values.invoiceType)) filter.invoiceType = { $in: values.invoiceType };
                    if (!_.isEmpty(values.fromTo)) filter.fromTo = values.fromTo;
                    await dispatch(searchInvoices(1, 99999999, filter))
                      .then(({ payload }) => {
                        const data = _.get(payload, "data", []).map((d) => { 
                          const item = _.pick(d, ["_ref", "date", "clientCompanyName", "clientFullName", "netTotal", "status", "taxTotal", "taxValue", "total"]); 
                          item.date = moment(item.date).format('DD/MM/YYYY');
                          item.netTotal = d.breakdown.netTotal;
                          item.taxTotal = d.breakdown.taxTotal;
                          item.taxValue = `${d.breakdown.taxValue}%`;
                          item.total = d.breakdown.total;
                          item.status = d.status === "issued" ? "Ενεργό" : "Ακυρομένο";
                          return item; 
                        }); 
                        setCSVData(data);
                        setCSVGenerated(true);
                      });
                  } catch (err) {
                    console.error(err);
                  }
                }}>
                  {intl.formatMessage({ id: "generateCSV" })}
                </Button>
                }
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </PageHeader>

      <Row gutter={8} style={{ background: "black", color: "white", fontWeight: 600, padding: 24, marginBottom: 16 }}>
        {loadingReport && (
          <Spin
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            size="small"
            tip={intl.formatMessage({ id: "loadingReport" })}
          />
        )}
        {!loadingReport && (
          <React.Fragment>
            <Col>
              <span>{intl.formatMessage({ id: "total" })}: €{reportData.total}</span>
            </Col>
            <Col>
              <span>{intl.formatMessage({ id: "net" })}: €{reportData.netTotal}</span>
            </Col>
            <Col>
              <span>{intl.formatMessage({ id: "vat" })}: €{reportData.taxTotal}</span>
            </Col>
          </React.Fragment>
        )}
      </Row>
      <InvoiceTable fetchData={fetchData} data={data} filters={form.getFieldsValue()} />
    </div>
  );
};
