import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Button,
  PageHeader,
  Popconfirm,
  Spin,
  Table,
} from "antd";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";


import { deleteItem, fetchItem } from "../../../redux/actions";

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [deleting, setDeleting] = useState(false);
  const data = useSelector((state) => state.items.data[id], shallowEqual);

  useEffect(() => {
    dispatch(fetchItem(id));
  }, []);

  const onDelete = () => {
    setDeleting(true);
    dispatch(deleteItem(id))
      .then(() => history.push("/items"))
      .catch(() => setDeleting(false));
  };

  const columns = [
    {
      title: intl.formatMessage({ id: "category" }),
      dataIndex: ["category"],
    },
    {
      title: intl.formatMessage({ id: "title" }),
      dataIndex: ["title"],
    },
    {
      title: intl.formatMessage({ id: "description" }),
      dataIndex: "description",
    },
    {
      title: intl.formatMessage({ id: "price" }),
      render: ({ price }) => price ? `€${price}` : '',
    },
    {
      title: intl.formatMessage({ id: "disabled" }),
      render: (record) => record?.disabled ?  intl.formatMessage({ id: "yes" }) :  intl.formatMessage({ id: "no" }),
    },
  ];

  if (_.isEmpty(data)) {
    return (
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
        size="large"
      />
    );
  }

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "viewItem" })}
        extra={[
          <Button
            key="edit"
            type="primary"
            onClick={() => history.push(`/items/edit/${id}`)}
            disabled={deleting}
          >
            {intl.formatMessage({ id: "edit" })}
          </Button>,
          <Popconfirm
            key="delete"
            title={intl.formatMessage({ id: "deleteConfirmation" })}
            onConfirm={onDelete}
            onCancel={() => null}
            okText={intl.formatMessage({ id: "yes" })}
            cancelText={intl.formatMessage({ id: "no" })}
          >
            <Button loading={deleting} key="delete" type="primary" danger>
              {intl.formatMessage({ id: "delete" })}
            </Button>
          </Popconfirm>,
        ]}
      />
      <div style={{ overflowX: 'scroll' }}>
        <Table columns={columns} dataSource={[data]} rowKey={(record) => record._id} pagination={false} />
      </div>
    </div>
  );
};
