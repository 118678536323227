export const LOGIN = "login";
export const SET_TOKEN = "SET_TOKEN";
export const LOGOUT = "logout";
export const FETCH_ME = "fetch-me";
export const SEND_NOTIFICATION = "send-notification";
export const REDIRECT = "redirect";
export const RESET = "RESET";
export const CHANGE_SYSTEM_LANGUAGE = "CHANGE_SYSTEM_LANGUAGE";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const FETCH_CONFIG = "FETCH_CONFIG";
export const FETCH_PUBLIC_CONFIG = "FETCH_CONFIG";
export const UPDATE_CONFIG = "UPDATE_CONFIG";

// Clients
export const SEARCH_CLIENTS = "SEARCH_CLIENTS";
export const FETCH_CLIENT = "FETCH_CLIENT";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const FETCH_STATEMENT = "FETCH_STATEMENT";
export const FORWARD_STATEMENT = 'FORWARD_STATEMENT';

//  Client Items
export const SEARCH_CLIENT_ITEMS = "SEARCH_CLIENT_ITEMS";
export const FETCH_CLIENT_ITEM = "FETCH_CLIENT_ITEM";
export const CREATE_CLIENT_ITEM = "CREATE_CLIENT_ITEM";
export const UPDATE_CLIENT_ITEM = "UPDATE_CLIENT_ITEM";
export const DELETE_CLIENT_ITEM = "DELETE_CLIENT_ITEM";

//  Invoices
export const SEARCH_INVOICES = "SEARCH_INVOICES";
export const CREATE_INVOICE = "CREATE_INVOICE";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const FETCH_INVOICE = "FETCH_INVOICE";
export const FETCH_PUBLIC_INVOICE = "FETCH_PUBLIC_INVOICE";
export const FORWARD_INVOICE = "FORWARD_INVOICE";
export const CANCEL_INVOICE = "CANCEL_INVOICE";
export const FETCH_CLIENT_BALANCE = "FETCH_CLIENT_BALANCE";
export const ISSUE_SUBSCRIPTION_INVOICE = "ISSUE_SUBSCRIPTION_INVOICE";
export const GENERATE_PUBLIC_ID_INVOICE = "GENERATE_PUBLIC_ID_INVOICE";
export const DELETE_JOB_FROM_INVOICE = "DELETE_JOB_FROM_INVOICE" 

//  Items
export const SEARCH_ITEMS = "SEARCH_ITEMS";
export const FETCH_ITEM = "FETCH_ITEM";
export const CREATE_ITEM = "CREATE_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";

//  Jobs
export const SEARCH_JOBS = "SEARCH_JOBS";
export const SEARCH_STORAGE = "SEARCH_STORAGE";
export const SEARCH_CLIENT_JOBS = "SEARCH_CLIENT_JOBS";
export const FETCH_JOB = "FETCH_JOB";
export const CREATE_JOB = "CREATE_JOB";
export const UPDATE_JOB = "UPDATE_JOB";
export const DELETE_JOB = "DELETE_JOB";

//  Payments
export const SEARCH_PAYMENTS = "SEARCH_PAYMENTS";
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const FETCH_PAYMENT = "FETCH_PAYMENT";
export const FETCH_PUBLIC_PAYMENT = "FETCH_PUBLIC_PAYMENT";
export const FORWARD_PAYMENT = "FORWARD_PAYMENT";
export const CANCEL_PAYMENT = "CANCEL_PAYMENT";
export const GENERATE_PUBLIC_ID_PAYMENT = "GENERATE_PUBLIC_ID_PAYMENT";

// Reports
export const FETCH_INVOICE_AMOUNT_PER_MONTH = "FETCH_INVOICE_AMOUNT_PER_MONTH";
export const FETCH_IMPORTED_EXPORTED_ITEMS = "FETCH_IMPORTED_EXPORTED_ITEMS";
export const FETCH_IN_ITEMS = "FETCH_IN_ITEMS";
export const FETCH_CLIENTS_BALANCE = "FETCH_CLIENTS_BALANCE";
