import React from "react";
import {
  AreaChartOutlined,
  AuditOutlined,
  BarcodeOutlined,
  ContactsOutlined,
  DashboardOutlined,
  FolderOutlined,
  MoneyCollectOutlined,
  SettingOutlined
} from "@ant-design/icons";

export default {
  sideMenuItems: [
    {
      path: "/clients",
      title: "clients",
      icon: <ContactsOutlined />,
      submenu: [
        {
          path: "/clients",
          exact: true,
          title: "view",
        },
        {
          path: "/clients/create",
          exact: true,
          title: "create",
        },
      ],
    },
    {
      path: "/clientItems",
      title: "clientItems",
      icon: <BarcodeOutlined />,
      submenu: [
        {
          path: "/clientItems",
          exact: true,
          title: "view",
        },
        {
          path: "/clientItems/create",
          exact: true,
          title: "create",
        },
      ],
    },
    {
      path: "/items",
      title: "items",
      icon: <FolderOutlined />,
      submenu: [
        {
          path: "/items",
          exact: true,
          title: "view",
        },
        {
          path: "/items/create",
          exact: true,
          title: "create",
        },
      ],
    },
    {
      path: "/jobs",
      title: "jobs",
      icon: <DashboardOutlined />,
      submenu: [
        {
          path: "/jobs",
          exact: true,
          title: "view",
        },
        {
          path: "/jobs/create",
          exact: true,
          title: "create",
        },
      ],
    },
    {
      path: "/invoices",
      title: "invoices",
      icon: <AuditOutlined />,
      submenu: [
        {
          path: "/invoices",
          exact: true,
          title: "view",
        },
        {
          path: "/invoices/create",
          exact: true,
          title: "create",
        },
      ],
    },
    {
      path: "/payments",
      title: "payments",
      icon: <MoneyCollectOutlined />,
      submenu: [
        {
          path: "/payments",
          exact: true,
          title: "view",
        },
        {
          path: "/payments/create",
          exact: true,
          title: "credit",
        },
      ],
    },
    {
      path: "/storage",
      title: "storage",
      icon: <DashboardOutlined />,
    },
    {
      path: "/reports/clients-balance",
      title: "reports",
      icon: <AreaChartOutlined />,
      submenu: [
        {
          path: "/reports/accountant-balance",
          exact: true,
          title: "accountantBalance",
        },
        {
          path: "/reports/clients-balance",
          exact: true,
          title: "clientsBalance",
        },
        {
          path: "/reports/invoices-per-month",
          exact: true,
          title: "invoices",
        },
      ]
    },
    {
      path: "/setup",
      title: "setup",
      icon: <SettingOutlined />,
    },
  ],
};
