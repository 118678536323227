import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Card, Checkbox, Col, Form, PageHeader, Popconfirm, Row, Spin, Table } from "antd";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import copy from 'copy-to-clipboard';
import _ from "lodash";
import moment from 'moment';

import { cancelInvoice, fetchInvoice, fetchPublicInvoice, forwardInvoice, generatePublicIdInvoice, sendNotification } from "../../../redux/actions";
import Modal from "antd/lib/modal/Modal";

export default ({ data = null, publicInvoice = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [cancelling, setCancelling] = useState(false);
  const [forwarding, setForwarding] = useState(false);
  const [forwardModalVisible, setForwardModalVisible] = useState(false);
  const [jobs, setJobs] = useState([]);

  const [forwardForm] = Form.useForm();

  const config = useSelector((state) => state.config, shallowEqual);
  const [invoice, setInvoice] = useState(data);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const renderDate = (date) => moment(date).format('DD/MM/YYYY');
  useEffect(() => {
    if (!id) {
      setLoading(false);
      return;
    };
    (publicInvoice ? dispatch(fetchPublicInvoice(id)) : dispatch(fetchInvoice(id)))
      .then(({ payload }) => {
        setInvoice(payload);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!invoice) return;
    const invoiceJobs = _.get(invoice, "jobs", []).flatMap((x) => {
      x.items.forEach((i) => i.startDate = x.startDate);
      return x.items;
    });
    if (invoice.subscription) {
      const newJobs = invoiceJobs.map((j) => {
        j.unitCost = 0;
        return j;
      });
      newJobs.push({
        item: {
          title: intl.formatMessage({ id: 'subscription' })
        },
        qty: 1,
        unitCost: invoice.breakdown.netTotal,
        _id: 1,
      });
      setJobs(newJobs);
    } else {
      setJobs(invoiceJobs);
    }
  }, [invoice]);

  if (loading) return <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} size="large" />;

  const columns = [
    {
      title: intl.formatMessage({ id: "productService" }),
      render: (record) => {
        return (
          <div>
            <span>{_.get(record, 'item.title')}</span><br/>
            <span style={{ fontStyle: "italic"}}>{_.get(record, 'notes')}</span>
          </div>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "qty" }),
      dataIndex: "qty",
    },
    {
      title: intl.formatMessage({ id: "unitCost" }),
      dataIndex: "unitCost",
      render: (text) => `€${render2Decimal(text)}`,
      align: "right",
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "total",
      render: (text, { qty, unitCost }) => {
        return `€${render2Decimal(qty * unitCost)}`;
      },
      align: "right",
    },
  ];

  invoice?.showDates && columns.splice(1, 0, {
    title: intl.formatMessage({ id: "date" }),
    render: (record) => renderDate(_.get(record, 'startDate'))
  });

  const renderAddress = (address) => {
    if (!address) return null;
    return `${_.get(address, 'street', '')}${address.unit ? ` ${address.unit}` : ''} ${_.get(address, 'district', '')}, ${_.get(address, 'city', '')} ${_.get(address, 'zip', '')}`;
  };

  const render2Decimal = (number) => {
    if (!number) return 0;
    return _.round(number, 2).toFixed(2);
  }

  const getClientBillingAddress = (client) => {
    const clientAddresses = _.get(client, 'addresses', []);
    if (_.isEmpty(clientAddresses)) return '';
    const address = clientAddresses.find(a => a.isBilling) || clientAddresses[0];
    return address;
  };

  const onCancel = () => {
    setCancelling(true);
    dispatch(cancelInvoice(id))
      .then(() => history.push("/invoices"))
      .catch(() => setCancelling(false));
  };

  const onForward = async () => {
    await forwardForm.validateFields();
    const { emails } = forwardForm.getFieldsValue();
    setForwarding(true);
    dispatch(forwardInvoice(id, emails))
      .finally(() => {
        setForwarding(false);
        onCancelModal();
      });
  };

  const onCancelModal = () => {
    setForwardModalVisible(false);
    forwardForm.resetFields();
  };

  const generatePublicId = () => {
    dispatch(generatePublicIdInvoice(id)).then((res) => setInvoice({ ...invoice, publicId: res.payload }));
  }

  const renderFax = (phones) => {
    if (_.isEmpty(phones)) return "";
    const faxes = _.castArray(phones.find(({ phoneType }) => phoneType === "Fax"));
    if (_.isEmpty(faxes)) return "";
    return faxes[0]?.phoneNumber?.toString();
  };

  return (
    <div className="page-wrapper">
      <Modal
        title={intl.formatMessage({ id: 'forward' })}
        visible={forwardModalVisible}
        onOk={onForward}
        onCancel={onCancelModal}
        okText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      >
        <Form form={forwardForm}>
          <Form.Item name="emails" rules={[{ required: true, message: intl.formatMessage({ id: "fieldRequired" }) }]}>
            <Checkbox.Group>
              {_.get(invoice, 'client.emails', []).map(e => (
                <div key={e._id}>
                  <Checkbox value={e.emailAddress}>
                    {e.emailAddress} ({e.emailType})
                  </Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "viewInvoice" })}
        extra={[
          !publicInvoice && !invoice.publicId && <Button
            key="generatePublicId"
            type="primary"
            onClick={() => generatePublicId()}
          >
            {intl.formatMessage({ id: "generatePublicId" })}
          </Button>,
          !publicInvoice && invoice.publicId && <Button
            key="copyLink"
            type="primary"
            onClick={() => {
              copy(`https://dnacleaning.com.cy/invoices/public/${invoice.publicId}`);
              dispatch(sendNotification({
                type: 'success',
                message: "copied",
                description: "linkCopiedToClipboard"
              }))
            }}
          >
            {intl.formatMessage({ id: "copyLink" })}
          </Button>,
          !publicInvoice && <Button
            key="forward"
            type="primary"
            disabled={forwarding || invoice.status === 'cancelled'}
            onClick={() => setForwardModalVisible(true)}
          >
            {intl.formatMessage({ id: "forward" })}
          </Button>,
           <Button
            key="edit"
            type="primary"
            onClick={() => history.push(`/invoices/edit/${id}`)}
          >
            {intl.formatMessage({ id: "edit" })}
          </Button>,
          <Button
            key="print"
            type="primary"
            onClick={handlePrint}
          >
            {intl.formatMessage({ id: "print" })}
          </Button>,
          !publicInvoice && (<Popconfirm
            key="delete"
            title={intl.formatMessage({ id: "cancelConfirmation" })}
            onConfirm={onCancel}
            onCancel={() => null}
            okText={intl.formatMessage({ id: "yes" })}
            cancelText={intl.formatMessage({ id: "no" })}
            disabled={cancelling || (invoice.status === 'cancelled')}
          >
            <Button
              key="cancel"
              type="danger"
              disabled={cancelling || (invoice.status === 'cancelled')}
            >
              {intl.formatMessage({ id: "cancel" })}
            </Button>
          </Popconfirm>)
        ]}
      />
      <div className="invoice" style={{ padding: 32, border: '1px solid #f5f5f5' }} ref={componentRef}>
        <Row style={{ marginBottom: 8 }} gutter={8}>
          <Col span={12}>
            <img
              src={"/images/dna.png"}
              alt="logo"
              style={{ width: 200 }}
            />
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                textAlign: "right",
              }}
            >
              <h1 style={{ fontSize: 32 }}>
                {intl.formatMessage({ id: "invoice" })}
              </h1>
              <span style={{ fontSize: 16 }}>
                {intl.formatMessage({ id: "invoiceNum" })}: {_.get(invoice, "_ref")}
              </span>
              <span style={{ fontSize: 16 }}>
                {intl.formatMessage({ id: "date" })}:{" "}
                {renderDate(_.get(invoice, "date"))}
              </span>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: 8 }} gutter={8}>
          <Col span={12}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              title={
                <h3 style={{ fontSize: 24, marginBottom: 0 }}>
                  {intl.formatMessage({ id: "from" })}
                </h3>
              }
              style={{ width: "100%", marginBottom: 8 }}
              bodyStyle={{ display: "flex", flexDirection: "column" }}
            >
              <h3>{config.companyName}</h3>
              <span>
                {renderAddress(config.address)}
              </span>
              <span>
                {intl.formatMessage({ id: "vat" })}: {config.taxID}
              </span>
              <span>
                {intl.formatMessage({ id: "phoneSmall" })}: {config.phone}
              </span>
              <span>
                {intl.formatMessage({ id: "fax" })}: {config.fax}
              </span>
              <span>
                {intl.formatMessage({ id: "email" })}: {config.emailAddress}
              </span>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              title={
                <h3 style={{ fontSize: 24, marginBottom: 0 }}>
                  {intl.formatMessage({ id: "client" })}
                </h3>
              }
              style={{ width: "100%", marginBottom: 8 }}
              bodyStyle={{ display: "flex", flexDirection: "column" }}
            >
              <h3>
                {_.get(invoice, "client.companyName")
                  ? _.get(invoice, "client.companyName")
                  : `${_.get(invoice, "client.firstName")} ${_.get(
                    invoice,
                    "client.lastName"
                  )}`}
              </h3>
              <span>
                {renderAddress(getClientBillingAddress(invoice.client))}
              </span>
              <span>
                {intl.formatMessage({ id: "name" })}:{" "}
                {_.get(invoice, "client.firstName")}{" "}
                {_.get(invoice, "client.lastName")}
              </span>
              <span>
                {intl.formatMessage({ id: "phoneSmall" })}:{" "}
                {_.get(invoice, "client.phones", [])
                  .filter((p) => p.phoneType !== "Fax")
                  .map((p) => p.phoneNumber)
                  .join(", ")}
              </span>
              <span>
                {intl.formatMessage({ id: "fax" })}:{" "}
                {renderFax(_.get(invoice, "client.phones", []))}
              </span>
              <span>
                {intl.formatMessage({ id: "email" })}:{" "}
                {_.get(invoice, "client.emails", [])
                  .map((e) => e.emailAddress)
                  .join(", ")}
              </span>
            </Card>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              title={intl.formatMessage({ id: "items" })}
              style={{ width: "100%", marginBottom: 16 }}
              bodyStyle={{ padding: 0, overflowX: "auto" }}
            >
              <Table
                size="small"
                bordered={true}
                columns={columns}
                dataSource={jobs}
                rowKey={(record) => _.get(record, '_id')}
                pagination={false}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              title={intl.formatMessage({ id: "notes" })}
              style={{ width: "100%", marginBottom: 8 }}
            >
              <strong>{intl.formatMessage({ id: "bankAccountNumber" })}:</strong> {_.get(config, 'bankAccountNumber', '')}
              <br/>
              <strong>{intl.formatMessage({ id: "bankDetails" })}:</strong> {_.get(config, 'bankDetails', '')}
              <br/>
              <strong>IBAN:</strong> {_.get(config, 'iban', '')}
              <br/><br/>
              {_.get(invoice, "notes", "")}
            </Card>
          </Col>
          <Col span={12}>
            <Card
              size="small"
              headStyle={{ backgroundColor: "#f0f0f0" }}
              title={intl.formatMessage({ id: "breakdown" })}
              style={{ width: "100%", marginBottom: 8 }}
              bodyStyle={{ display: "flex", justifyContent: "flex-end" }}
            >
              <table className="invoice-below-table" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>{intl.formatMessage({ id: "subTotal" })}:</td>
                    <td style={{ textAlign: "right" }}>€{render2Decimal(_.get(invoice, "breakdown.subTotal"))}</td>
                  </tr>
                  <tr>
                    <td>{intl.formatMessage({ id: "discount" })}:</td>
                    <td style={{ textAlign: "right" }}>€{render2Decimal(_.get(invoice, "breakdown.discount"))}</td>
                  </tr>
                  <tr>
                    <td>{intl.formatMessage({ id: "totalNetWorth" })}:</td>
                    <td style={{ textAlign: "right" }}>€{render2Decimal(_.get(invoice, "breakdown.netTotal"))}</td>
                  </tr>
                  <tr>
                    <td>
                      {intl.formatMessage({ id: "vat" })} ({config.taxValue}%):
                  </td>
                    <td style={{ textAlign: "right" }}>€{render2Decimal(_.get(invoice, "breakdown.taxTotal"))}</td>
                  </tr>
                  <tr>
                    <td>{intl.formatMessage({ id: "total" })}:</td>
                    <td style={{ textAlign: "right" }}>€{render2Decimal(_.get(invoice, "breakdown.total"))}</td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
