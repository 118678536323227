import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Avatar, Button, Card, Col, PageHeader, Row, Spin, Table, Tag, Typography } from "antd";
import { useIntl } from "react-intl";
import _ from "lodash";
import Storage from "../../Storage";

import {
  fetchClient,
  fetchClientBalance, issueSubscriptionInvoice,
  searchClientItems,
  searchInvoices,
  searchJobs,
  searchPayments, searchStorage
} from "../../../redux/actions";
import JobTable from "../../Jobs/JobTable";
import ClientItemTable from "../../ClientItems/ClientItemsTable";
import InvoiceTable from "../../Invoices/InvoiceTable";
import PaymentTable from "../../Payments/PaymentTable";

const { Paragraph } = Typography;

export default () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [activeTab, setActiveTab] = useState("pendingWork");
  const [loading, setLoading] = useState(true);
  const [createInvoice, setCreateInvoice] = useState(false);
  const [client, setClient] = useState(null);
  const [balance, setBalance] = useState(0);

  const [pendingWorkData, setPendingWorkData] = useState({ data: [], total: 0 });
  const [jobData, setJobData] = useState({ data: [], total: 0 });
  const [invoiceData, setInvoiceData] = useState({ data: [], total: 0 });
  const [paymentData, setPaymentData] = useState({ data: [], total: 0 });
  const [clientItemsData, setClientItemsData] = useState({ data: [], total: 0 });
  const [storageData, setStorageData] = useState({ data: [], total: 0 });

  useEffect(() => {
    Promise.all([
      dispatch(fetchClient(id)),
      dispatch(fetchClientBalance(id))
    ])
      .then(([{ payload: clientPayload }, { payload: balancePayload }]) => {
        setClient(clientPayload);
        setBalance(balancePayload.total);
        setLoading(false)
      })
  }, []);

  const tabList = [
    {
      key: "pendingWork",
      tab: intl.formatMessage({ id: "pendingWork" }),
    },
    {
      key: "jobs",
      tab: intl.formatMessage({ id: "jobs" }),
    },
    {
      key: "invoices",
      tab: intl.formatMessage({ id: "invoices" }),
    },
    {
      key: "payments",
      tab: intl.formatMessage({ id: "payments" }),
    },
    {
      key: "clientItems",
      tab: intl.formatMessage({ id: "clientItems" }),
    },
    {
      key: "storage",
      tab: intl.formatMessage({ id: "storage" }),
    },
  ];

  const fetchData = (query, setState, additionalFilters = {}) => (page, size, filters = {}, sort = {}) => {
    return dispatch(query(page, size, { ...filters,  client: id, ...additionalFilters }, sort))
      .then(({ payload }) => setState(payload));
  };

  const contentList = {
    pendingWork: <JobTable key="pendingWork" data={pendingWorkData} fetchData={fetchData(searchJobs, setPendingWorkData, { endDate: null })} />,
    jobs: <JobTable key="jobs" data={jobData} fetchData={fetchData(searchJobs, setJobData)} />,
    invoices: <InvoiceTable key="invoices" data={invoiceData} fetchData={fetchData(searchInvoices, setInvoiceData)} />,
    payments: <PaymentTable key="payments" data={paymentData} fetchData={fetchData(searchPayments, setPaymentData)} />,
    clientItems: (
      <ClientItemTable key="clientItems" data={clientItemsData} fetchData={fetchData(searchClientItems, setClientItemsData)} />
    ),
    storage: (
      <Storage
        key="storage"
        data={storageData}
        showReportData={false}
        fetchData={fetchData(searchStorage, setStorageData)}
      />
    ),
  };

  if (loading) {
    return <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} size="large" />
  }

  const onClickCreateInvoice = () => {
    setCreateInvoice(true);
    dispatch(issueSubscriptionInvoice(id))
      .then(({ payload }) => {
        history.push(`/invoices/${payload._id}`)
      })
      .catch(() => setCreateInvoice(false))
  };

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 16 }}
          >
            <Avatar
              style={{
                backgroundColor: "#f56a00",
                verticalAlign: "middle",
                marginRight: 8,
              }}
              size="large"
            >
              #{_.get(client, "_ref")}
            </Avatar>
            <span style={{ fontSize: 32 }}>
              {client.firstName} {client.lastName} [
              {client.companyName ? client.companyName : null}]
            </span>
          </div>
        }
        extra={[
          <Button
            key="edit"
            type="primary"
            onClick={() => history.push(`/clients/edit/${id}`)}
          >
            {intl.formatMessage({ id: "edit" })}
          </Button>,
          <Button
            key="statement"
            type="primary"
            onClick={() => history.push(`/clients/statement/${client.publicId}`)}
          >
            {intl.formatMessage({ id: "statement" })}
          </Button>
        ]}
      />

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
          <Card
            style={{ width: "100%", marginBottom: 16 }}
            title={intl.formatMessage({ id: "properties" })}
            headStyle={{ backgroundColor: "#f0f0f0" }}
            bodyStyle={{ padding: 0, overflowX: 'scroll' }}
          >
            <Table
              columns={[
                {
                  title: intl.formatMessage({ id: "city" }),
                  dataIndex: "city",
                  key: "city",
                },
                {
                  title: intl.formatMessage({ id: "district" }),
                  dataIndex: "district",
                  key: "district",
                },
                {
                  title: intl.formatMessage({ id: "zip" }),
                  dataIndex: "zip",
                  key: "zip",
                },
                {
                  title: intl.formatMessage({ id: "street" }),
                  dataIndex: "street",
                  key: "street",
                },
                {
                  title: intl.formatMessage({ id: "unit" }),
                  dataIndex: "unit",
                  key: "unit",
                },
                {
                  title: intl.formatMessage({ id: "notes" }),
                  render: (record) => record.notes ? <Paragraph ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}>{record.notes}</Paragraph> : null,
                  key: "notes",
                },
                {
                  title: intl.formatMessage({ id: "isBilling" }),
                  dataIndex: "isBilling",
                  key: "isBilling",
                  render: (value) => value ? <Tag color="green">Yes</Tag> : <Tag color="lightgray">No</Tag>,
                },
              ]}
              dataSource={client.addresses}
              rowKey={(record) => record._id}
              pagination={false}
              size="small"
              bordered={true}
            />
          </Card>
          <Card
            style={{ width: "100%" }}
            bodyStyle={{ padding: 0 }}
            title={intl.formatMessage({ id: "overview" })}
            tabList={tabList}
            activeTabKey={activeTab}
            onTabChange={(key) => setActiveTab(key)}
            headStyle={{ backgroundColor: "#f0f0f0" }}
          >
            {contentList[activeTab]}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          <Card
            style={{ width: "100%", marginBottom: 16 }}
            title={intl.formatMessage({ id: "balance" })}
            headStyle={{ backgroundColor: "#f0f0f0" }}
            bodyStyle={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ fontWeight: 500, fontSize: 24 }}>
              {intl.formatMessage({ id: "balance" })}: €{balance.toFixed(2)}
            </div>
          </Card>
          {_.get(client, 'subscriptionInvoice.enabled') &&
          <Card
            style={{ width: "100%", marginBottom: 16 }}
            title={intl.formatMessage({ id: "subscription" })}
            headStyle={{ backgroundColor: "#f0f0f0" }}
            bodyStyle={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: 'center' }}
          >
            <div style={{ fontWeight: 500, fontSize: 32 }}>
              €{_.get(client, 'subscriptionInvoice.amount')}
            </div>
            <div style={{ marginTop: 16 }}>
              <Button onClick={onClickCreateInvoice} disabled={createInvoice}>{intl.formatMessage({ id: "createInvoice" })}</Button>
            </div>
            {_.get(client, 'subscriptionInvoice.invoiceType') && (
              <div style={{ fontWeight: 500, fontSize: 12, marginTop: 8 }}>
              {intl.formatMessage({ id: "invoiceType" })}: {intl.formatMessage({ id: _.get(client, 'subscriptionInvoice.invoiceType') })}
            </div>
            )}
            <div style={{ fontSize: 12, marginTop: 4 }}>
              {intl.formatMessage({ id: 'subscriptionInvoiceCreationMessage'})}
            </div>
          </Card>}
          <Card
            style={{ width: "100%", marginBottom: 16 }}
            title={intl.formatMessage({ id: "contactInfo" })}
            headStyle={{ backgroundColor: "#f0f0f0" }}
            bodyStyle={{ padding: 0, overflow: 'scroll' }}
          >
            <div
              style={{
                padding: 8,
                fontSize: 16,
                color: "black",
                fontWeight: 500,
                background: "#fafafa",
                textAlign: "center",
                borderBottom: "1px solid lightgray",
              }}
            >
              {intl.formatMessage({ id: "phones" })}
            </div>
            <Table
              bordered={true}
              columns={[
                {
                  title: intl.formatMessage({ id: "phoneType" }),
                  render: (record) => record.phoneType ? intl.formatMessage({ id: record.phoneType }) : null,
                },
                {
                  title: intl.formatMessage({ id: "phoneNumber" }),
                  dataIndex: "phoneNumber",
                  key: "phoneNumber",
                },
              ]}
              dataSource={client.phones}
              rowKey={(record) => record._id}
              pagination={false}
              size="small"
            />
            <div
              style={{
                padding: 8,
                fontSize: 16,
                color: "black",
                fontWeight: 500,
                background: "#fafafa",
                textAlign: "center",
                borderBottom: "1px solid lightgray",
                borderTop: "3px solid lightgray",
              }}
            >
              {intl.formatMessage({ id: "emails" })}
            </div>
            <Table
              bordered={true}
              columns={[
                {
                  title: intl.formatMessage({ id: "emailType" }),
                  render: (record) => record.emailType ? intl.formatMessage({ id: record.emailType }) : null
                },
                {
                  title: intl.formatMessage({ id: "emailAddress" }),
                  dataIndex: "emailAddress",
                  key: "emailAddress",
                },
              ]}
              dataSource={client.emails}
              rowKey={(record) => record._id}
              pagination={false}
              size="small"
            />
          </Card>
          {client.notes && (
            <Card
              style={{ width: "100%", marginBottom: 16 }}
              title={intl.formatMessage({ id: "notes" })}
              headStyle={{ backgroundColor: "#f0f0f0" }}
            >
              {client.notes}
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};
