import React from "react";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import moment from 'moment';
import _ from 'lodash';
import { Button, Typography } from 'antd';

import ArchiveTable from "../../components/ArchiveTable";

const dateRenderer = (date) => {
  if (!date) return;
  return moment(date).format('DD/MM/YYYY');
}

const { Paragraph } = Typography;

export default ({ fetchData, data }) => {
  const history = useHistory();
  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage({ id: "actions" }),
      className: "actions-column",
      render: (record) => <Link to={`/jobs/${record.job}`}>{intl.formatMessage({ id: "viewJob" })}</Link>
    },
    {
      title: intl.formatMessage({ id: "client" }),
      render: (record) => _.get(record, 'client') ? (
        <Button
          type="link"
          onClick={() => history.push(`/clients/${_.get(record, 'client')}`)}
          style={{ marginRight: 8 }}
        >
          #{_.get(record, 'clientRef')} {_.get(record, 'clientFullName')} {_.get(record, 'clientCompanyName') ? `[${_.get(record, 'clientCompanyName')}]` : ''}
        </Button>
      ) : null,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: "category" }),
      render: (record) => record.clientItemCategory ? intl.formatMessage({ id: record.clientItemCategory }) : ''
    },
    {
      title: intl.formatMessage({ id: "ref" }),
      dataIndex: "clientItemRef",
    },
    {
      title: intl.formatMessage({ id: "clientItem" }),
      dataIndex: 'clientItemTitle',
    },
    {
      title: intl.formatMessage({ id: "startDate" }),
      render: (record) => dateRenderer(record.startDate)
    },
    {
      title: intl.formatMessage({ id: "notes" }),
      render: (record) => record.notes ? <Paragraph ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}>{record.notes}</Paragraph> : null
    },
  ];

  return (
    <ArchiveTable
      fetchData={fetchData}
      columns={columns}
      data={data}
      rowKey={(record) => `${record._id}-${record.clientItemRef}` }
    />
  );
};
