import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, PageHeader, Switch, Table } from "antd";
import _ from "lodash";
import moment from "moment";
import { CSVLink } from "react-csv";

import { fetchClientsBalanceReport } from "../../redux/actions";

export default (props) => {

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAllClients, setShowAllClients] = useState(false);
  const [clientsBalanceData, setClientsBalanceData] = useState([]);
  const [loadingClientsBalance, setLoadingClientsBalance] = useState(true);
  const [csvData, setCSVData] = useState([]);
  const [csvGenerated, setCSVGenerated] = useState(false);

  const totalBalance = _.sumBy(clientsBalanceData, (o) => o.all.balance);

  useEffect(() => {
    dispatch(fetchClientsBalanceReport())
      .then(({ payload }) => setClientsBalanceData(payload))
      .then(() => setLoadingClientsBalance(false));
  }, []);

  const renderAmount = (value) => `€${(Math.round((value + Number.EPSILON) * 100) / 100).toFixed(2)}`;
  const renderDate = (date) => moment(date).format('DD/MM/YYYY');

  const clientsBalanceColumns = [
    {
      title: intl.formatMessage({ id: "client" }),
      render: (record) => _.get(record, 'client') ? (
        <Button
          type="link"
          onClick={() => history.push(`/clients/${_.get(record, 'client._id')}`)}
          style={{ marginRight: 8 }}
        >
        #{_.get(record, 'client._ref')} {_.get(record, 'client.firstName')} {_.get(record, 'client.lastName')} {_.get(record, 'client.companyName') ? ` [${_.get(record, 'client.companyName')}]` : ''}
        </Button>
      ) : null,
    },
    {
      title: intl.formatMessage({ id: "total" }),
      children: [{
        title: intl.formatMessage({ id: "balance" }),
        render: ({ all }) => renderAmount(all.balance),
      }]
    },
    {
      title: intl.formatMessage({ id: "currentMonth" }),
      children: [{
        title: intl.formatMessage({ id: "invoiced" }),
        render: ({ currentMonth }) => renderAmount(currentMonth.invoice),
      }, {
        title: intl.formatMessage({ id: "balance" }),
        render: ({ currentMonth }) => renderAmount(currentMonth.balance),
      }]
    },
    {
      title: intl.formatMessage({ id: "previousMonth" }),
      children: [{
        title: intl.formatMessage({ id: "invoiced" }),
        render: ({ previousMonth }) => renderAmount(previousMonth.invoice),
      }, {
        title: intl.formatMessage({ id: "balance" }),
        render: ({ previousMonth }) => renderAmount(previousMonth.balance),
      }]
    },
    {
      title: intl.formatMessage({ id: "secondPreviousMonth" }),
      children: [{
        title: intl.formatMessage({ id: "invoiced" }),
        render: ({ secondPreviousMonth }) => renderAmount(secondPreviousMonth.invoice),
      }, {
        title: intl.formatMessage({ id: "balance" }),
        render: ({ secondPreviousMonth }) => renderAmount(secondPreviousMonth.balance),
      }]
    },
    {
      title: intl.formatMessage({ id: "thirdPreviousMonth" }),
      children: [{
        title: intl.formatMessage({ id: "invoiced" }),
        render: ({ thirdPreviousMonth }) => renderAmount(thirdPreviousMonth.invoice),
      }, {
        title: intl.formatMessage({ id: "balance" }),
        render: ({ thirdPreviousMonth }) => renderAmount(thirdPreviousMonth.balance),
      }]
    },
    {
      title: intl.formatMessage({ id: "restMonths" }),
      children: [{
        title: intl.formatMessage({ id: "invoiced" }),
        render: ({ rest }) => renderAmount(rest.invoice),
      }, {
        title: intl.formatMessage({ id: "balance" }),
        render: ({ rest }) => renderAmount(rest.balance),
      }]
    },
    {
      title: intl.formatMessage({ id: "latestPayment" }),
      children: [{
        title: intl.formatMessage({ id: "amount" }),
        render: ({ latestPayment }) => latestPayment ? renderAmount(latestPayment.amount) : null,
      }, {
        title: intl.formatMessage({ id: "transactionDate" }),
        render: ({ latestPayment }) => latestPayment ? renderDate(latestPayment.transactionDate) : null,
      }]
    },
  ];

  const csvHeaders = [{
    label: "Πελάτης",
    key: "client"
  }, {
    label: "Ολικό Υπόλοιπο Ποσό",
    key: "totalBalance"
  }, {
    label: "Τιμολόγηση - Υπάρχων Μήνας",
    key: "currentMonthInvoices"
  }, {
    label: "Υπόλοιπο - Υπάρχων Μήνας",
    key: "currentMonthBalance"
  }, {
    label: "Τιμολόγηση - Προηγούμενος Μήνας",
    key: "previousMonthInvoices"
  }, {
    label: "Υπόλοιπο - Προηγούμενος Μήνας",
    key: "previousMonthBalance"
  }, {
    label: "Τιμολόγηση - Δύο Μήνες Πριν",
    key: "twoMonthsInvoices"
  }, {
    label: "Υπόλοιπο - Δύο Μήνες Πριν",
    key: "twoMonthsBalance"
  }, {
    label: "Τιμολόγηση - Τρείς Μήνες Πριν",
    key: "threeMonthsInvoices"
  }, {
    label: "Υπόλοιπο - Τρείς Μήνες Πριν",
    key: "threeMonthsBalance"
  }, {
    label: "Τιμολόγηση - Προηγούμενοι Μήνες",
    key: "restMonthsInvoices"
  }, {
    label: "Υπόλοιπο - Προηγούμενοι Μήνες",
    key: "restMonthsBalance"
  },{
    label: "Ημερομηνία Τελευταίας Πληρωμής",
    key: "latestPaymentOn"
  },{
    label: "Ποσό Τελευταίας Πληρωμής",
    key: "latestPaymentAmount"
  }];

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "reports" })}
        extra={[
          csvGenerated
          ?
          <CSVLink data={csvData} headers={csvHeaders}>
            <Button type="primary">{intl.formatMessage({ id: "downloadCSV" })}</Button>
          </CSVLink>
        : <Button type="primary" onClick={async (event) => {
            try {
              const data = clientsBalanceData.filter((i) => i?.all?.balance > 0).map((item) => {
                const row = {
                  client: `${item?.client?.companyName || ""} (${item?.client?.firstName || ""} ${item?.client?.lastName || ""})`,
                  totalBalance: item?.all?.balance,
                  currentMonthInvoices: item?.currentMonth?.invoice,
                  currentMonthBalance: item?.currentMonth?.balance,
                  previousMonthInvoices: item?.previousMonth?.invoice,
                  previousMonthBalance: item?.previousMonth?.balance,
                  twoMonthsInvoices: item?.secondPreviousMonth?.invoice,
                  twoMonthsBalance: item?.secondPreviousMonth?.balance,
                  threeMonthsInvoices: item?.thirdPreviousMonth?.invoice,
                  threeMonthsBalance: item?.thirdPreviousMonth?.balance,
                  restMonthsInvoices: item?.rest?.invoice,
                  restMonthsBalance: item?.rest?.balance,
                  latestPaymentOn: item?.latestPayment?.transactionDate ? moment(item?.latestPayment?.transactionDate).format('DD/MM/YYYY') : "",
                  latestPaymentAmount: item?.latestPayment?.amount || ""
                }
                return row
              });
              setCSVData(data);
              setCSVGenerated(true);
            } catch (err) {
              console.error(err);
            }
          }}>{intl.formatMessage({ id: "generateCSV" })}</Button>,
          <span key="label">{intl.formatMessage({ id: "showAllClients" })}: </span>,
          <Switch key="showAllClients" onChange={setShowAllClients} />,
          <span key="totalBalance" style={{ fontWeight: "bold", fontSize: 18, backgroundColor: "black", color: "white", padding: 8 }}>{intl.formatMessage({ id: "totalBalance" })}: {renderAmount(totalBalance)}</span>,
        ]}
      />
      <Table
        rowKey={(record) => record.client._id}
        dataSource={showAllClients ? clientsBalanceData : clientsBalanceData.filter((c) => _.get(c, 'all.balance') !== 0)}
        columns={clientsBalanceColumns}
        loading={loadingClientsBalance}
        bordered
        scroll={{ x: 1300 }} 
      />
    </div>
  )
}
