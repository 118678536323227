import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import middleware from './redux/middlewares';
import rootReducer from './redux/reducers'
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import { initialiseToken } from './redux/actions';
import 'antd/dist/antd.css'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(middleware)));

store.dispatch(initialiseToken());

const App = (props) => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <MainPage />
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
