import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Form, notification, PageHeader, Popconfirm, Spin, } from "antd";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import ClientItemForm from "../ClientItemForm";
import { deleteClientItem, fetchClientItem, updateClientItem } from "../../../redux/actions";

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const config = useSelector((state) => state.config, shallowEqual);

  useEffect(() => {
    dispatch(fetchClientItem(id))
      .then(({ payload }) => setData(payload))
      .then(() => setLoading(false));
  }, []);

  const onSave = async () => {

    const clientItem = form.getFieldsValue();
    setSubmitting(true);
    dispatch(updateClientItem(id, clientItem))
      .then(() => history.push(`/clientItems/${id}`))
      .catch(() => setSubmitting(false));
  };

  const onDelete = () => {
    setSubmitting(true);
    dispatch(deleteClientItem(id))
      .then(() => history.push("/clientItems"))
      .catch(() => setSubmitting(false));
  };

  const priceCalculator = () => {
    const { category, dimensions } = form.getFieldsValue();
    if (!category) return;
    const categoryCalculator = config.priceCalculator.find((i) => i.clientItemCategory === category);
    if (!categoryCalculator) return notification.warning({ message: intl.formatMessage({ id: "notFound" }), description: intl.formatMessage({ id: "priceCalculatorNotFoundForThisCategory" })});
    const { algorithm } = categoryCalculator;
    const price = eval(algorithm);
    if (isNaN(price)) return;
    form.setFieldsValue({ price });
  }

  if (loading) {
    return <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} size="large" />
  }

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "editClientItem" })}
        extra={[
          <Button
            key="priceCalculator"
            type="primary"
            onClick={priceCalculator}
            disabled={submitting}
          >
            {intl.formatMessage({ id: "priceCalculator" })}
          </Button>,
          <Button
            key="edit"
            type="primary"
            onClick={onSave}
            loading={submitting}
          >
            {intl.formatMessage({ id: "save" })}
          </Button>,
          <Popconfirm
            key="delete"
            title={intl.formatMessage({ id: "deleteConfirmation" })}
            onConfirm={onDelete}
            disabled={submitting}
            onCancel={() => null}
            okText={intl.formatMessage({ id: "yes" })}
            cancelText={intl.formatMessage({ id: "no" })}
          >
            <Button disabled={submitting} type="primary" danger>
              {intl.formatMessage({ id: "delete" })}
            </Button>
          </Popconfirm>,
        ]}
      />
      <ClientItemForm
        defaultValues={data}
        form={form}
      />
    </div>
  );
};
