import React, { useEffect, useState } from "react";
import InvoiceForm from "../InvoiceForm";
import { Button, Form, PageHeader, Popconfirm, Spin } from "antd";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { cancelInvoice, fetchInvoice, updateInvoice } from "../../../redux/actions";
import moment from "moment";
import _ from "lodash";

export default () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [breakdown, setBreakdown] = useState({});
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchInvoice(id))
      .then(({ payload }) => {
        form.setFieldsValue({
          ...payload,
          date: moment(payload.date),
          dueDate: moment(payload.dueDate),
          discount: _.get(payload, "breakdown.discount", 0),
        })
        setCancelling(payload.status === "cancelled");
      })
      .then(() => setLoading(false))
  }, [id])

  const onClick = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      setSubmitting(true);
      await dispatch(
        updateInvoice(id, {
          ...values,
          breakdown,
          client: values.client._id,
          discount: breakdown.discount ?? 0,
          jobs: values.jobs.map((j) => j._id),
        })
      );
      history.push(`/invoices`);
    } catch {
      setSubmitting(false);
    }
  }

  const onCancel = () => {
    setCancelling(true)
    dispatch(cancelInvoice(id))
      .then(() => history.push("/invoices"))
      .catch(() => setCancelling(false))
  }

  if (loading) {
    return (
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
        size="large"
      />
    )
  }

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "editInvoice" })}
        extra={[
          <Button
            key="save"
            type="primary"
            disabled={submitting}
            onClick={onClick}
          >
            {intl.formatMessage({ id: "save" })}
          </Button>,
          <Popconfirm
            key="delete"
            title={intl.formatMessage({ id: "cancelConfirmation" })}
            onConfirm={onCancel}
            onCancel={() => null}
            okText={intl.formatMessage({ id: "yes" })}
            cancelText={intl.formatMessage({ id: "no" })}
            disabled={cancelling}
          >
            <Button key="cancel" type="danger" disabled={cancelling}>
              {intl.formatMessage({ id: "cancel" })}
            </Button>
          </Popconfirm>,
        ]}
      />
      <InvoiceForm
        editing={true}
        form={form}
        getBreakdown={setBreakdown}
        invoiceId={id}
      />
    </div>
  )
}
