import React from "react";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";

import { Button, Table } from "antd";

const AccountantBalanceTable = ({ loading, data, showAllClients }) => {
  const intl = useIntl();
  const history = useHistory();

  const renderAmount = (value) =>
    `€${(Math.round((value + Number.EPSILON) * 100) / 100).toFixed(2)}`;

  const columns = [
    {
      title: intl.formatMessage({ id: "client" }),
      render: (record) =>
        _.get(record, "client") ? (
          <Button
            type="link"
            onClick={() => history.push(`/clients/${_.get(record, "client._id")}`)}
            style={{ marginRight: 8 }}
          >
            #{_.get(record, "client._ref")} {_.get(record, "client.firstName")}{" "}
            {_.get(record, "client.lastName")}{" "}
            {_.get(record, "client.companyName") ? ` [${_.get(record, "client.companyName")}]` : ""}
          </Button>
        ) : null,
    },
    {
      title: intl.formatMessage({ id: "invoicedAmount" }),
      render: ({ invoicedAmount }) => renderAmount(invoicedAmount),
    },
    {
      title: intl.formatMessage({ id: "balance" }),
      render: ({ balance }) => renderAmount(balance),
    },
    {
      title: intl.formatMessage({ id: "invoices" }),
      render: ({ invoices }) =>
        invoices.map(({ _id, _ref }, index) => (
          <React.Fragment key={_id}>
            {index > 0 && ", "}
            <Link key={_id} to={`/invoices/${_id}`} style={{ marginRight: 2 }}>
              #{_ref}
            </Link>
          </React.Fragment>
        )),
    },
    {
      title: intl.formatMessage({ id: "unpaidInvoices" }),
      render: ({ invoices }) =>
        invoices
          .filter(({ paid }) => !paid)
          .map(({ _id, _ref }, index) => (
            <React.Fragment key={_id}>
              {index > 0 && ", "}
              <Link key={_id} to={`/invoices/${_id}`} style={{ marginRight: 2 }}>
                #{_ref}
              </Link>
            </React.Fragment>
          )),
    },
    {
      title: intl.formatMessage({ id: "payments" }),
      render: ({ payments }) =>
        payments.map(({ _id, _ref }, index) => (
          <React.Fragment key={_id}>
            {index > 0 && ", "}
            <Link to={`/payments/${_id}`}>#{_ref}</Link>
          </React.Fragment>
        )),
    },
  ];
  return (
    <Table
      rowKey={(record) => record.client._id}
      dataSource={showAllClients ? data : data.filter(({ balance }) => balance > 0)}
      columns={columns}
      loading={loading}
      bordered
      scroll={{ x: 1300 }}
    />
  );
};

export default AccountantBalanceTable;
