import React from "react";
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from "react-intl";
import {
  Form,
  Input,
  Row,
  Col,
  InputNumber,
  Select,
  Switch,
} from "antd";

const { Option } = Select;
const { TextArea } = Input;

export default ({ form }) => {
  const intl = useIntl();
  const categories = ['Product', 'Service'];
  const requiredRule = {
    required: true,
    message: intl.formatMessage({ id: "fieldRequired" }),
  };

  const renderOptions = (values) =>
    values.map((v) => (
      <Option key={v} value={v}>
        {intl.formatMessage({ id: v })}
      </Option>
    ));

  return (
    <Form name="itemForm" layout="vertical" form={form} initialValues={{ category: 'Service' }}>
      <Row gutter={32}>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Form.Item
            name="title"
            label={intl.formatMessage({ id: "title" })}
            rules={[requiredRule]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
          <Form.Item
            name="category"
            label={intl.formatMessage({ id: "category" })}
            rules={[requiredRule]}
          >
            <Select>{renderOptions(categories)}</Select>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
          <Form.Item name="price" label={intl.formatMessage({ id: "price" })}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
          <Form.Item name="disabled" label={intl.formatMessage({ id: "disabled" })} valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            name="description"
            label={intl.formatMessage({ id: "description" })}
          >
            <TextArea autoSize />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
