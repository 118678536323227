import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Modal, notification, PageHeader, Popconfirm, Spin, } from "antd";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import JobForm from "../JobForm";
import { deleteJob, fetchJob, updateJob } from "../../../redux/actions";
import _ from "lodash";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) =>
  notification[type]({ message, description });

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchJob(id))
      .then(({ payload }) => setData({ ...payload, items: payload.items.map(item => ({ ...item, clientItem: _.get(item, 'clientItem._id'), item: _.get(item, 'item._id')}))}))
      .then(() => setLoading(false));
  }, []);

  const onSave = async () => {
    try {
      await form.validateFields();
      const job = form.getFieldsValue();
      if (!job.client)
        return Modal.warning({
          title: intl.formatMessage({ id: "clientRequired" }),
          content: intl.formatMessage({ id: "clientRequiredDescription" }),
        });
      setSubmitting(true);
      dispatch(updateJob(id, job))
        .then(() => history.push(`/jobs/${id}`))
        .catch(() => setSubmitting(false));
    } catch (err) {
      if (err.errorFields) {
        err.errorFields
          .filter(({ name }) => name.includes("client"))
          .map(({ name, errors }) =>
            openNotificationWithIcon(
              "warning",
              name.join(", "),
              errors.join(", ")
            )
          );
      }
    }
  };

  const onDelete = () => {
    setSubmitting(true);
    dispatch(deleteJob(id))
      .then(() => history.push("/jobs"))
      .catch(() => setSubmitting(false));
  };

  if (_.isEmpty(data)) {
    return (
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
        size="large"
      />
    );
  }

  if (loading) {
    return <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} size="large" />
  }

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "editJob" })}
        extra={[
          <Button
            key="edit"
            type="primary"
            onClick={onSave}
            loading={submitting}
          >
            {intl.formatMessage({ id: "save" })}
          </Button>,
          <Popconfirm
            title={intl.formatMessage({ id: "deleteConfirmation" })}
            onConfirm={onDelete}
            disabled={submitting}
            onCancel={() => null}
            okText={intl.formatMessage({ id: "yes" })}
            cancelText={intl.formatMessage({ id: "no" })}
            key="delete"
          >
            <Button disabled={submitting} type="primary" danger>
              {intl.formatMessage({ id: "delete" })}
            </Button>
          </Popconfirm>,
        ]}
      />
      <JobForm
        defaultValues={{
          ...data,
          startDate: data.startDate && moment(data.startDate),
          dueDate: data.dueDate && moment(data.dueDate),
        }}
        form={form}
      />
    </div>
  );
};
