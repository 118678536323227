import * as types from "./types";

const baseRoute = "v1/client";
const statementRoute = "v1/statement/public";

export const searchClients = (page, size, filters = {}, sort = {}) => {
  return (api) => ({
    type: types.SEARCH_CLIENTS,
    payload: api.post(`${baseRoute}/search`, { page, size, filters, sort }),
  });
};

export const fetchClient = (id) => {
  return (api) => ({
    type: types.FETCH_CLIENT,
    payload: api.get(`${baseRoute}/${id}`),
  });
};

export const createClient = (data) => {
  return (api) => ({
    type: types.CREATE_CLIENT,
    payload: api.post(baseRoute, data),
    success: {
      message: "createClient",
      description: "createClientSuccess",
    },
  });
};

export const updateClient = (id, data) => {
  return (api) => ({
    type: types.UPDATE_CLIENT,
    payload: api.put(`${baseRoute}/${id}`, data),
    success: {
      message: "updateClient",
      description: "updateClientSuccess",
    },
  });
};

export const deleteClient = (id) => {
  return (api) => ({
    type: types.DELETE_CLIENT,
    payload: api.delete(`${baseRoute}/${id}`),
    id,
    success: {
      message: "deleteClient",
      description: "deleteClientSuccess",
    },
  });
};

export const fetchStatement = (publicId) => {
  let url = `${statementRoute}/${publicId}`;
  return (api) => ({
    type: types.FETCH_STATEMENT,
    payload: api.get(url),
  });
};

export const forwardStatement = (id, emails) => {
  return (api) => ({
    type: types.FORWARD_STATEMENT,
    payload: api.post(`${baseRoute}/statement/forward/${id}`, { emails }),
    showUserErrors: true,
    success: {
      message: "forwardStatement",
      description: "forwardStatementSuccess",
    },
  });
};
