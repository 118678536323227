import * as types from "./types";

const baseRoute = "v1/clientItem";

export const searchClientItems = (page, size, filters = {}, sort = {}) => {
  return (api) => ({
    type: types.SEARCH_CLIENT_ITEMS,
    payload: api.post(`${baseRoute}/search`, { page, size, filters, sort }),
  });
};

export const fetchClientItem = (id) => {
  return (api) => ({
    type: types.FETCH_CLIENT_ITEM,
    payload: api.get(`${baseRoute}/${id}`),
  });
};

export const createClientItem = (data) => {
  return (api) => ({
    type: types.CREATE_CLIENT_ITEM,
    payload: api.post(baseRoute, data),
    success: {
      message: "createClientItem",
      description: "createSuccess",
    },
    showUserErrors: true
  });
};

export const updateClientItem = (id, data) => {
  return (api) => ({
    type: types.UPDATE_CLIENT_ITEM,
    payload: api.put(`${baseRoute}/${id}`, data),
    success: {
      message: 'updateClientItem',
      description: 'updateSuccess'
    },
    showUserErrors: true
  });
};

export const deleteClientItem = (id) => {
  return (api) => ({
    type: types.DELETE_CLIENT_ITEM,
    payload: api.delete(`${baseRoute}/${id}`),
    id,
    success: {
      message: 'deleteClientItem',
      description: 'deleteSuccess'
    }
  });
};
