import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, PageHeader } from "antd";
import { searchClientItems } from "../../redux/actions";
import ClientItemsTable from "./ClientItemsTable";

export default ({ hideHeader, presetFilters }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState({ data: [], total: 0 });

  const fetchData = (page, size, filters = {}, sort = {}) => {
    return dispatch(searchClientItems(page, size, filters, sort))
      .then(({ payload }) => setData(payload));
  };

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "clientItems" })}
        extra={[
          <Button
            key="create"
            type="primary"
            onClick={() => history.push("/clientItems/create")}
          >
            {intl.formatMessage({ id: "create" })}
          </Button>,
        ]}
      />
      <ClientItemsTable fetchData={fetchData} data={data} />
    </div>
  );
};
