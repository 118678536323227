import React from "react";
import { useIntl } from "react-intl";
import {
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  InputNumber,
  Select,
  Switch,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { contactTitle, emailType, phoneType } from "../../constants";

const { Option } = Select;
const { TextArea } = Input;

export default ({ form }) => {
  const intl = useIntl();

  const requiredRule = {
    required: true,
    message: intl.formatMessage({ id: "fieldRequired" }),
  };

  const renderOptions = (values) =>
    values.map((v) => (
      <Option key={v} value={v}>
        {intl.formatMessage({ id: v })}
      </Option>
    ));

  return (
    <Form
      name="clientForm"
      layout="vertical"
      form={form}
    >
      <Row gutter={32}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Row gutter="16">
            <Col span={24}>
              <Form.Item
                name="companyName"
                label={intl.formatMessage({ id: "companyName" })}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="firstName"
                label={intl.formatMessage({ id: "firstName" })}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastName"
                label={intl.formatMessage({ id: "lastName" })}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={["subscriptionInvoice", "enabled"]}
                label={intl.formatMessage({ id: "subscriptionInvoiceEnabled" })}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={intl.formatMessage({ id: "invoiceType" })}
                name={["subscriptionInvoice", "invoiceType"]}
              >
              <Select>
                <Option key="invoice" value="invoice">{intl.formatMessage({ id: 'invoice' })}</Option>
                <Option key="other" value="other">{intl.formatMessage({ id: 'other' })}</Option>
              </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["subscriptionInvoice", "amount"]}
                label={intl.formatMessage({ id: "subscriptionInvoiceAmount" })}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="notes"
                label={intl.formatMessage({ id: "notes" })}
                >
                  <Input.TextArea rows={8} />
                </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.List name="phones">
            {(fields, { add, remove }) => {
              return (
                <Card
                  title={intl.formatMessage({ id: "phones" })}
                  extra={
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                    >
                      <PlusOutlined /> {intl.formatMessage({ id: "add" })}
                    </Button>
                  }
                  bodyStyle={{ padding: "0px 8px" }}
                  style={{ margin: "8px 0px" }}
                >
                  {fields.map((field, index) => (
                    <Row
                      gutter="16"
                      key={`phones-${index}`}
                      style={{ border: "1px dashed #d9d9d9", padding: 8 }}
                    >
                      <Col span={10}>
                        <Form.Item
                          {...field}
                          label={intl.formatMessage({ id: "phoneType" })}
                          name={[field.name, "phoneType"]}
                          fieldKey={[field.fieldKey, "phoneType"]}
                          rules={[requiredRule]}
                        >
                          <Select>{renderOptions(phoneType)}</Select>
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          {...field}
                          label={intl.formatMessage({ id: "phoneNumber" })}
                          name={[field.name, "phoneNumber"]}
                          fieldKey={[field.fieldKey, "phoneNumber"]}
                          rules={[requiredRule]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xs={4}>
                        <Form.Item label={intl.formatMessage({ id: "remove" })}>
                          <Button
                            danger
                            type="dashed"
                            onClick={() => {
                              remove(field.name);
                            }}
                          >
                            <MinusCircleOutlined />
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </Card>
              );
            }}
          </Form.List>
          <Form.List name="emails">
            {(fields, { add, remove }) => {
              return (
                <Card
                  title={intl.formatMessage({ id: "emails" })}
                  extra={
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                    >
                      <PlusOutlined /> {intl.formatMessage({ id: "add" })}
                    </Button>
                  }
                  bodyStyle={{ padding: "0px 8px" }}
                  style={{ margin: "8px 0px" }}
                >
                  {fields.map((field, index) => (
                    <Row
                      gutter="16"
                      key={`emails-${index}`}
                      style={{ border: "1px dashed #d9d9d9", padding: 8 }}
                    >
                      <Col span={10}>
                        <Form.Item
                          {...field}
                          label={intl.formatMessage({ id: "emailType" })}
                          name={[field.name, "emailType"]}
                          fieldKey={[field.fieldKey, "emailType"]}
                          rules={[requiredRule]}
                        >
                          <Select>{renderOptions(emailType)}</Select>
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          {...field}
                          label={intl.formatMessage({ id: "emailAddress" })}
                          name={[field.name, "emailAddress"]}
                          fieldKey={[field.fieldKey, "emailAddress"]}
                          rules={[requiredRule]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={4}>
                        <Form.Item label={intl.formatMessage({ id: "remove" })}>
                          <Button
                            danger
                            type="dashed"
                            onClick={() => {
                              remove(field.name);
                            }}
                          >
                            <MinusCircleOutlined />
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </Card>
              );
            }}
          </Form.List>
          <Form.List name="addresses">
            {(fields, { add, remove }) => {
              return (
                <Card
                  title={intl.formatMessage({ id: "addresses" })}
                  extra={
                    <Button
                      type="dashed"
                      onClick={() => add()}
                    >
                      <PlusOutlined /> {intl.formatMessage({ id: "add" })}
                    </Button>
                  }
                  bodyStyle={{ padding: "0px 8px" }}
                  style={{ margin: "8px 0px" }}
                >
                  {fields.map((field, index) => (
                    <Row
                      gutter="16"
                      key={`addresses-${index}`}
                      style={{ border: "1px dashed #d9d9d9", padding: 8 }}
                    >
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item
                          {...field}
                          label={intl.formatMessage({ id: "city" })}
                          name={[field.name, "city"]}
                          fieldKey={[field.fieldKey, "city"]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item
                          {...field}
                          label={intl.formatMessage({ id: "district" })}
                          name={[field.name, "district"]}
                          fieldKey={[field.fieldKey, "district"]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Form.Item
                          {...field}
                          label={intl.formatMessage({ id: "zip" })}
                          name={[field.name, "zip"]}
                          fieldKey={[field.fieldKey, "zip"]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={16} lg={18} xl={18}>
                        <Form.Item
                          {...field}
                          label={intl.formatMessage({ id: "street" })}
                          name={[field.name, "street"]}
                          fieldKey={[field.fieldKey, "street"]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Form.Item
                          {...field}
                          label={intl.formatMessage({ id: "unit" })}
                          name={[field.name, "unit"]}
                          fieldKey={[field.fieldKey, "unit"]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={16} lg={18} xl={14}>
                        <Form.Item
                          {...field}
                          label={intl.formatMessage({ id: "notes" })}
                          name={[field.name, "notes"]}
                          fieldKey={[field.fieldKey, "notes"]}
                        >
                          <TextArea autoSize allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Form.Item
                          {...field}
                          label={intl.formatMessage({ id: "isBilling" })}
                          name={[field.name, "isBilling"]}
                          fieldKey={[field.fieldKey, "isBilling"]}
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                      <Col xs={4}>
                        <Form.Item label={intl.formatMessage({ id: "remove" })}>
                          <Button
                            danger
                            type="dashed"
                            onClick={() => {
                              remove(field.name);
                            }}
                          >
                            <MinusCircleOutlined />
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </Card>
              );
            }}
          </Form.List>
        </Col>
      </Row>
    </Form>
  );
};
