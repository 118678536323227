import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import _ from 'lodash';

import ArchiveTable from "../../components/ArchiveTable";

export default ({ fetchData, data }) => {

  const intl = useIntl();
  const history = useHistory();

  const columns = [
    {
      title: intl.formatMessage({ id: "actions" }),
      className: "actions-column",
      render: (record) => (
        <div>
          <Button
            type="link"
            onClick={() => history.push(`/clientItems/${record._id}`)}
            style={{ marginRight: 8 }}
          >
            {intl.formatMessage({ id: "view" })}
          </Button>
          <Button
            type="link"
            onClick={() => history.push(`/clientItems/edit/${record._id}`)}
          >
            {intl.formatMessage({ id: "edit" })}
          </Button>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: "ref" }),
      dataIndex: "_ref",
    },
    {
      title: intl.formatMessage({ id: "previousRef" }),
      dataIndex: "_previousRef",
    },
    {
      title: intl.formatMessage({ id: "client" }),
      render: (record) => _.get(record, 'client') ? (
        <Button
          type="link"
          onClick={() => history.push(`/clients/${_.get(record, 'client._id')}`)}
          style={{ marginRight: 8 }}
        >
          #{_.get(record, 'client._ref')} {_.get(record, 'clientFullName')} {_.get(record, 'clientCompanyName') ? `[${_.get(record, 'clientCompanyName')}]` : ''}
        </Button>
      ) : null,
    },
    {
      title: intl.formatMessage({ id: "title" }),
      dataIndex: "title",
    },
    {
      title: intl.formatMessage({ id: "category" }),
      render: (record) => intl.formatMessage({ id: record.category })
    },
    {
      title: intl.formatMessage({ id: "price" }),
      render: ({ price }) => price ? `€${price}` : '',
    },
    {
      title: intl.formatMessage({ id: "length" }),
      render: (record) => record.dimensions ? `${_.get(record, 'dimensions.length')} cm` : null
    },
    {
      title: intl.formatMessage({ id: "width" }),
      render: (record) => record.dimensions ? `${_.get(record,'dimensions.width')} cm` : null
    },
    {
      title: intl.formatMessage({ id: "description" }),
      dataIndex: "description",
    },
  ];

  return (
    <ArchiveTable
      fetchData={fetchData}
      columns={columns}
      data={data}
    />
  );
};
