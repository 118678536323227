import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button } from "antd";

import ArchiveTable from "../../components/ArchiveTable";

export default ({ fetchData, data }) => {
  const intl = useIntl();
  const history = useHistory();

  const columns = [
    {
      title: intl.formatMessage({ id: "actions" }),
      className: "actions-column",
      render: (record) => (
        <div>
          <Button
            type="link"
            onClick={() => history.push(`/items/${record._id}`)}
            style={{ marginRight: 8 }}
          >
            {intl.formatMessage({ id: "view" })}
          </Button>
          <Button
            type="link"
            onClick={() => history.push(`/items/edit/${record._id}`)}
          >
            {intl.formatMessage({ id: "edit" })}
          </Button>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: "category" }),
      render: (record) => record.category ? intl.formatMessage({ id: record.category }) : null,
    },
    {
      title: intl.formatMessage({ id: "title" }),
      dataIndex: "title",
    },

    {
      title: intl.formatMessage({ id: "description" }),
      dataIndex: "description",
    },
    {
      title: intl.formatMessage({ id: "price" }),
      render: (record) => record.price ? `€${record.price}` : '',
    },
    {
      title: intl.formatMessage({ id: "disabled" }),
      render: (record) => record?.disabled ?  intl.formatMessage({ id: "yes" }) :  intl.formatMessage({ id: "no" }),
    },
  ];

  return (
    <ArchiveTable
      fetchData={fetchData}
      columns={columns}
      data={data}
    />
  );
};
