import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  DatePicker,
  Descriptions,
  Form,
  Input,
  PageHeader,
  Popconfirm,
  Spin,
  Table,
  Typography
} from "antd";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import {
  AuditOutlined,
  UserOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import moment from "moment";

import { completeJob, deleteJob, fetchJob, updateJob } from "../../../redux/actions";
import Modal from "antd/lib/modal/Modal";

const renderDate = (date) => date ? moment(date).format('DD/MM/YYYY') : '';

const { Paragraph } = Typography;

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const data = useSelector((state) => state.jobs.data[id], shallowEqual);

  const requiredRule = {
    required: true,
    message: intl.formatMessage({ id: "fieldRequired" }),
  };

  useEffect(() => {
    dispatch(fetchJob(id));
  }, []);

  const onDelete = () => {
    setDeleting(true);
    dispatch(deleteJob(id))
      .then(() => history.push("/jobs"))
      .catch(() => setDeleting(false));
  };

  const onCompleteJob = () => {
    setUpdating(true);
    dispatch(completeJob(id)).finally(() => {
      setUpdating(false);
    });
  };

  const onImport = ({ date, notes }) => {
    const { items } = data;
    const updatedItem = data.items.find((i) => i._id === selectedItem._id);
    updatedItem.storage = {
      ...updatedItem.storage,
      notes,
      startDate: date,
    };
    setUpdating(true);
    dispatch(updateJob(id, { items })).finally(() => {
      setUpdating(false);
      setImportModalVisible(false);
    });
  };

  const onExport = ({ date, notes }) => {
    const { items } = data;
    const updatedItem = data.items.find((i) => i._id === selectedItem._id);
    updatedItem.storage = {
      ...updatedItem.storage,
      endDate: date,
    };
    updatedItem.notes = notes;
    setUpdating(true);
    dispatch(updateJob(id, { items })).finally(() => {
      setUpdating(false);
      setExportModalVisible(false);
    });
  };

  const columns = [
    {
      title: intl.formatMessage({ id: "productService" }),
      dataIndex: ["item", "title"],
    },
    {
      title: intl.formatMessage({ id: "clientItem" }),
      render: (record) => {
        if (!record.clientItem) return null;
        
        const length = _.get(record,'clientItem.dimensions.length');
        const width = _.get(record,'clientItem.dimensions.width');
        const _ref = _.get(record, 'clientItem._ref');
        const text = `#${_ref} - ${record.clientItem.title} (${length}cm * ${width}cm)`;
        return <Button type="link" onClick={() => history.push(`/clientItems/${_.get(record, 'clientItem._id')}`)}>
          {text}
      </Button>
      }
    },
    {
      title: intl.formatMessage({ id: "qty" }),
      dataIndex: "qty",
    },
    {
      title: intl.formatMessage({ id: "unitCost" }),
      dataIndex: "unitCost",
      render: (text) => `€${text.toFixed(2)}`,
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "total",
      render: (text, { qty, unitCost }) => {
        return `€${(qty * unitCost).toFixed(2)}`;
      },
    },
    {
      title: intl.formatMessage({ id: "storage" }),
      dataIndex: ["storage", "notes"],
    },
    {
      title: intl.formatMessage({ id: "importDate" }),
      render: (record) => 
        _.get(record, 'storage.startDate') ? (
          moment(record.storage.startDate).format('DD/MM/YYYY')
        ) : data.endDate || _.isEmpty(record.clientItem) ? null : (
          <Button
            type="link"
            onClick={() => {
              setSelectedItem(record);
              setImportModalVisible(true);
            }}
            disabled={deleting}
          >
            {intl.formatMessage({ id: "import" })}
          </Button>
        ),
    },
    {
      title: intl.formatMessage({ id: "exportDate" }),
      render: (record) => (
        _.get(record, 'storage.endDate') ? moment(record.storage.endDate).format('DD/MM/YYYY') :
          (_.get(record, 'storage.startDate') ? (<Button
          type="link"
          onClick={() => {
            setSelectedItem(record);
            setExportModalVisible(true);
          }}
          disabled={deleting}
        >
          {intl.formatMessage({ id: "export" })}
        </Button>) : null)
      )
    },
    {
      title: intl.formatMessage({ id: "notes" }),
      render: (record) => record.notes ? <Paragraph ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}>{record.notes}</Paragraph> : null
    },
  ];

  if (_.isEmpty(data)) {
    return (
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
        size="large"
      />
    );
  }

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "viewJob" })}
        extra={[
          _.isEmpty(data.endDate) ? (
            <Button
              icon={<CheckCircleOutlined />}
              key="completeJob"
              type="primary"
              disabled={deleting}
              loading={updating}
              onClick={() => onCompleteJob()}
            >
              {intl.formatMessage({ id: "completeJob" })}
            </Button>
          ) : null,
          <Button
            key="edit"
            type="primary"
            onClick={() => history.push(`/jobs/edit/${id}`)}
            disabled={!_.isNil(data.invoice) || deleting}
          >
            {intl.formatMessage({ id: "edit" })}
          </Button>,
          <Popconfirm
            key="delete"
            disabled={!_.isNil(data.invoice)}
            title={intl.formatMessage({ id: "deleteConfirmation" })}
            onConfirm={onDelete}
            onCancel={() => null}
            okText={intl.formatMessage({ id: "yes" })}
            cancelText={intl.formatMessage({ id: "no" })}
          >
            <Button disabled={!_.isNil(data.invoice)} loading={deleting} key="delete" type="primary" danger>
              {intl.formatMessage({ id: "delete" })}
            </Button>
          </Popconfirm>,
        ]}
      >
        <Descriptions size="small" column={3}>
          <Descriptions.Item label={intl.formatMessage({ id: "client" })}>
            <Button
              icon={<UserOutlined />}
              type="link"
              onClick={() =>
                history.push(`/clients/${_.get(data, "client._id")}`)
              }
            >
              {_.get(data, "client.firstName")} {_.get(data, "client.lastName")}
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: "companyName" })}>
            <Button
              icon={<UserOutlined />}
              type="link"
              onClick={() =>
                history.push(`/clients/${_.get(data, "client._id")}`)
              }
            >
              {_.get(data, "client.companyName")}
          </Button>
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: "creationDate" })}>
            {renderDate(_.get(data, "_createdOn"))}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: "startDate" })}>
            {renderDate(_.get(data, "startDate"))}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: "dueDate" })}>
            {renderDate(_.get(data, "dueDate"))}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: "endDate" })}>
            {renderDate(_.get(data, "endDate"))}
          </Descriptions.Item>

          <Descriptions.Item label={intl.formatMessage({ id: "invoice" })}>
            {!_.isEmpty(_.get(data, "invoice")) && (
              <Button
                icon={<AuditOutlined />}
                type="link"
                onClick={() =>
                  history.push(`/invoices/${_.get(data, "invoice._id")}`)
                }
              >
                {data.invoice.invoiceType === 'other' ? `O-${_.padStart(data.invoice._ref, 6, '0')}` : `I-${_.padStart(data.invoice._ref, 6, '0')}`}
              </Button>
            )}
            {_.isEmpty(_.get(data, "invoice")) && !_.isEmpty(_.get(data, "endDate")) && (
              <Button
                icon={<AuditOutlined />}
                key="createInvoice"
                type="link"
                onClick={() => history.push({
                  pathname: `/invoices/create`,
                  state: { client: data.client }
                })}
                disabled={deleting}
              >
                {intl.formatMessage({ id: "createInvoice" })}
              </Button>
            )}
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <Card
        size="small"
        headStyle={{ backgroundColor: "#f0f0f0" }}
        title={intl.formatMessage({ id: "instructions" })}
        style={{ width: "100%", marginBottom: 16 }}
      >
        {data.instructions}
      </Card>
      <div style={{ overflowX: 'scroll' }}>
        <Table
          bordered={true}
          columns={columns}
          dataSource={data.items}
          rowKey={(record) => record._id}
          size="small"
        />
      </div>
      <Modal
        title={intl.formatMessage({ id: "import" })}
        centered
        visible={importModalVisible}
        footer={null}
        mask={true}
        onCancel={() => setImportModalVisible(false)}
      >
        <Form
          onFinish={onImport}
          layout="vertical"
          initialValues={{
            date: moment(),
            notes: _.get(selectedItem, "storage.notes", ""),
          }}
        >
          <Form.Item
            label={intl.formatMessage({ id: "date" })}
            name="date"
            rules={[requiredRule]}
          >
            <DatePicker style={{ width: "100%" }} format={'DD/MM/YYYY'} disabledDate={current => {
              return current && current > moment();
            }} />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: "storage" })}
            name="notes"
            rules={[requiredRule]}
          >
            <Input.TextArea autoSize />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit">
              {intl.formatMessage({ id: "submit" })}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={intl.formatMessage({ id: "export" })}
        centered
        visible={exportModalVisible}
        footer={null}
        mask={true}
        onCancel={() => setExportModalVisible(false)}
      >
        <Form
          onFinish={onExport}
          layout="vertical"
          initialValues={{
            date: moment(),
            notes: _.get(selectedItem, "notes", ""),
          }}
        >
          <Form.Item
            label={intl.formatMessage({ id: "date" })}
            name="date"
            rules={[requiredRule]}
          >
            <DatePicker style={{ width: "100%" }} format={'DD/MM/YYYY'} disabledDate={current => {
              return current && current > moment();
            }} />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: "notes" })}
            name="notes"
          >
            <Input.TextArea autoSize />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit">
              {intl.formatMessage({ id: "submit" })}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
