import * as types from "../actions/types";
import _ from "lodash";

const payments = (
  state = {
    data: {},
    search: {
      data: [],
      total: 0,
    },
  },
  action
) => {
  switch (action.type) {
    case types.SEARCH_PAYMENTS: {
      return {
        ...state,
        data: { ...state.data, ..._.keyBy(action.payload.data, "_id") },
        search: {
          ...action.payload,
          data: action.payload.data.map((r) => r._id),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default {
  payments,
};
