import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { CSVLink } from "react-csv";

import { Button, Col, Form, PageHeader, Row, Spin, Switch } from "antd";

import SearchForm from "./partials/SearchForm";
import Table from "./partials/Table";

import { searchInvoices, searchPayments } from "../../../redux/actions";

const AccountantBalance = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [payments, setPayments] = useState([]);
  const [data, setData] = useState([]);
  const [showAllClients, setShowAllClients] = useState(false);

  const [csvData, setCSVData] = useState([]);
  const [csvGenerated, setCSVGenerated] = useState(false);

  const [form] = Form.useForm();
  const initialFilters = {
    fromTo: [
      moment().quarter(moment().quarter()).startOf("quarter"),
      moment().quarter(moment().quarter()).endOf("quarter"),
    ],
    showAllClients: false,
  };

  const loadData = async (page = 1, size = 999999, sort = { date: 1 }) => {
    await form.validateFields();
    const filters = form.getFieldsValue();
    filters.invoiceType = "invoice";
    filters.status = "issued";
    setLoading(true);
    await Promise.all([
      dispatch(searchInvoices(page, size, filters, sort)),
      dispatch(searchPayments(page, size, { fromTo: filters.fromTo }, { transactionDate: 1 })),
    ])
      .then(([invoicesResult, paymentsResult]) => {
        setInvoices(invoicesResult.payload.data);
        setPayments(paymentsResult.payload.data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (_.isEmpty(invoices)) return;

    const invoicesPerClient = _.groupBy(invoices, "client._id");
    const paymentsPerClient = _.groupBy(payments, "client._id");
    const modifiedData = Object.entries(invoicesPerClient).map(([key, invoices]) => {
      const invoicesIds = invoices.map(({ _id }) => _id);
      const payments = paymentsPerClient[key]?.filter(({ invoice }) => invoicesIds.includes(invoice[0]._id)) || [];
      const invoicedAmount = _.sumBy(invoices, (i) => i.breakdown.total);
      const paymentAmount = _.sumBy(payments, (p) => p.amount);
      if (isNaN(paymentAmount)) console.log(key)
      return {
        _id: key,
        client: invoices[0].client,
        invoices,
        payments,
        invoicedAmount,
        paymentAmount,
        balance: invoicedAmount - paymentAmount,
      };
    });
    setData(modifiedData);
    setLoading(false);
  }, [invoices, payments]);

  const csvHeaders = [
    {
      label: "Πελάτης",
      key: "client",
    },
    {
      label: "Ποσό Τιμολογίου",
      key: "invoicedAmount",
    },
    {
      label: "Υπόλοιπο",
      key: "balance",
    },
    {
      label: "Τιμολόγια",
      key: "invoices",
    },
    {
      label: "Απλήρωτα Τιμολόγια",
      key: "unpaidInvoices",
    },
    {
      label: "Πληρωμές",
      key: "payments",
    },
  ];

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "accountantBalance" })}
        extra={[
          csvGenerated ? (
            <CSVLink data={csvData} headers={csvHeaders}>
              <Button type="primary">{intl.formatMessage({ id: "downloadCSV" })}</Button>
            </CSVLink>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                try {
                  const generatedData = data.map((item) => {
                    const row = {
                      client: `${item?.client?.companyName || ""} (${
                        item?.client?.firstName || ""
                      } ${item?.client?.lastName || ""})`,
                      invoicedAmount: item?.invoicedAmount,
                      balance: item?.balance,
                      invoices: item?.invoices?.map(({ _ref }) => `#${_ref}`).join(", "),
                      unpaidInvoices: item?.invoices
                        ?.filter(({ paid }) => !paid)
                        ?.map(({ _ref }) => `#${_ref}`)
                        .join(", "),
                      payments: item?.payments?.map(({ _ref }) => `#${_ref}`).join(", "),
                    };
                    return row;
                  });
                  setCSVData(generatedData);
                  setCSVGenerated(true);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {intl.formatMessage({ id: "generateCSV" })}
            </Button>
          ),

          <span key="label">{intl.formatMessage({ id: "showAllClients" })}: </span>,
          <Switch key="showAllClients" checked={showAllClients} onChange={setShowAllClients} />,
        ]}
      />
      <SearchForm form={form} initialFilters={initialFilters} loadData={loadData} />
      <Row gutter={8} style={{ background: "black", color: "white", fontWeight: 600, padding: 24, marginBottom: 16 }}>
        {loading && (
          <Spin
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            size="small"
            tip={intl.formatMessage({ id: "loadingReport" })}
          />
        )}
        {!loading && (
          <React.Fragment>
            <Col>
              <span>
                {intl.formatMessage({ id: "balance" })}: €{_.sumBy(data, "balance").toFixed(2)}
              </span>
            </Col>
            <Col>
              <span>
                {intl.formatMessage({ id: "invoicedAmount" })}: €
                {_.sumBy(
                  showAllClients ? data : data.filter(({ balance }) => balance > 0),
                  "invoicedAmount"
                ).toFixed(2)}
              </span>
            </Col>
            <Col>
              <span>
                {intl.formatMessage({ id: "paymentAmount" })}: €
                {_.sumBy(
                  showAllClients ? data : data.filter(({ balance }) => balance > 0),
                  "paymentAmount"
                ).toFixed(2)}
              </span>
            </Col>
          </React.Fragment>
        )}
      </Row>
      <Table
        loading={loading}
        showAllClients={showAllClients}
        data={data}
      />
    </div>
  );
};

export default AccountantBalance;
