import * as types from "../actions/types";
import _ from "lodash";

const invoices = (
  state = {
    data: {},
    search: {
      data: [],
      total: 0,
    },
  },
  action
) => {
  switch (action.type) {
    case types.SEARCH_INVOICES: {
      return {
        ...state,
        data: { ...state.data, ..._.keyBy(action.payload.data, "_id") },
        search: {
          ...action.payload,
          data: action.payload.data.map((r) => r._id),
        },
      };
    }
    case types.CREATE_INVOICE:
    case types.FETCH_INVOICE:
    case types.FETCH_PUBLIC_INVOICE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload._id]: action.payload,
        },
      };
    }
    case types.DELETE_INVOICE: {
      const newSearchData = state.search.data.filter(id => id !== action.id);
      return {
        ...state,
        data: _.omit(state.data, action.id),
        search: {
          data: newSearchData,
          total: newSearchData.length !== state.search.data ? state.search.total - 1 : state.search.total,
        }
      }
    }
    default: {
      return state;
    }
  }
};

export default {
  invoices,
};
