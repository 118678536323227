import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Button,
  PageHeader,
  Popconfirm,
  Spin,
  Table,
} from "antd";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";


import { deleteClientItem, fetchClientItem } from "../../../redux/actions";

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [deleting, setDeleting] = useState(false);
  const data = useSelector((state) => state.clientItems.data[id], shallowEqual);

  useEffect(() => {
    dispatch(fetchClientItem(id));
  }, []);

  const onDelete = () => {
    setDeleting(true);
    dispatch(deleteClientItem(id))
      .then(() => history.push("/clientItems"))
      .catch(() => setDeleting(false));
  };

  const renderClient = (client) => {
    if (_.isEmpty(client)) return null;
    const companyName = client.companyName ? `[${client.companyName}]` : '';
    return `${_.get(client, 'firstName')} ${_.get(client, 'lastName')} ${companyName}`;
  }

  const columns = [
    {
      title: intl.formatMessage({ id: "ref" }),
      dataIndex: "_ref",
    },
    {
      title: intl.formatMessage({ id: "previousRef" }),
      dataIndex: "_previousRef",
    },
    {
      title: intl.formatMessage({ id: "client" }),
      render: ({ client }) => renderClient(client)
    },
    {
      title: intl.formatMessage({ id: "title" }),
      dataIndex: "title",
    },
    {
      title: intl.formatMessage({ id: "category" }),
      render: (record) => record.category ? intl.formatMessage({ id: record.category }) : null
    },
    {
      title: intl.formatMessage({ id: "price" }),
      render: ({ price }) => price ? `€${price}` : '',
    },
    {
      title: intl.formatMessage({ id: "length" }),
      render: (record) => record.dimensions ? `${_.get(record, 'dimensions.length')} cm` : null
    },
    {
      title: intl.formatMessage({ id: "width" }),
      render: (record) => record.dimensions ? `${_.get(record, 'dimensions.width')} cm` : null
    },
    {
      title: intl.formatMessage({ id: "description" }),
      dataIndex: "description",
    },
  ];

  if (_.isEmpty(data)) {
    return (
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
        size="large"
      />
    );
  }

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "viewClientItem" })}
        extra={[
          <Button
            key="edit"
            type="primary"
            onClick={() => history.push(`/clientItems/edit/${id}`)}
            disabled={deleting}
          >
            {intl.formatMessage({ id: "edit" })}
          </Button>,
          <Popconfirm
            key="delete"
            title={intl.formatMessage({ id: "deleteConfirmation" })}
            onConfirm={onDelete}
            onCancel={() => null}
            okText={intl.formatMessage({ id: "yes" })}
            cancelText={intl.formatMessage({ id: "no" })}
          >
            <Button loading={deleting} key="delete" type="primary" danger>
              {intl.formatMessage({ id: "delete" })}
            </Button>
          </Popconfirm>,
        ]}
      />
      <div style={{ overflowX: 'scroll' }}>
        <Table columns={columns} dataSource={[data]} rowKey={(record) => record._id} pagination={false} />
      </div>
    </div>
  );
};
