import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, } from "antd";
import _ from "lodash";

import Clients from "../Clients";

const { TextArea } = Input;
const { Option } = Select;

export default ({ defaultValues = {}, form }) => {
  const intl = useIntl();
  //  Local State
  const [client, setClient] = useState(defaultValues.client);
  const [clientsModalVisible, setClientsModalVisible] = useState(false);
  // Redux State
  const config = useSelector((state) => state.config, shallowEqual);
  const categories = _.get(config, "clientItemsCategories", []);

  const requiredRule = {
    required: true,
    message: intl.formatMessage({ id: "fieldRequired" }),
  };

  const renderOptions = (values) =>
    values.map((v) => (
      <Option key={v} value={v}>
        {intl.formatMessage({ id: v })}
      </Option>
    ));

  const onClientSelection = (value, row) => {
    setClient(row[0]);
    form.setFieldsValue({ client: row[0]._id });
    setClientsModalVisible(false);
  };

  const renderClientName = () => {
    if (!client) return intl.formatMessage({ id: "selectClient" });
    return `${client.firstName} ${client.lastName} [${client.companyName}]`;
  };

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        initialValues={defaultValues}
      >
        <Row gutter="16">
          <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="_previousRef" label={intl.formatMessage({ id: "previousRef" })}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={6}>
            <Form.Item
              name="title"
              label={intl.formatMessage({ id: "title" })}
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
            <Form.Item name="client" label={intl.formatMessage({ id: "client" })} rules={[requiredRule]}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Input value={renderClientName()} disabled />
                <Button
                  type="primary"
                  onClick={() => setClientsModalVisible(true)}
                >
                  {intl.formatMessage({ id: "select" })}
                </Button>
              </div>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6}>
            <Form.Item
              name="category"
              label={intl.formatMessage({ id: "category" })}
              rules={[requiredRule]}
            >
              <Select>{renderOptions(categories)}</Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item
              name="description"
              label={intl.formatMessage({ id: "description" })}
            >
              <TextArea autoSize />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} xl={4}>
            <Form.Item
              name={["dimensions", "width"]}
              label={intl.formatMessage({ id: "width" })}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} xl={4}>
            <Form.Item
              name={["dimensions", "length"]}
              label={intl.formatMessage({ id: "length" })}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} xl={4}>
            <Form.Item
              name="price"
              label={intl.formatMessage({ id: "price" })}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        bodyStyle={{ padding: 0 }}
        footer={null}
        onCancel={() => setClientsModalVisible(false)}
        title={intl.formatMessage({ id: "selectClient" })}
        visible={clientsModalVisible}
        width="75%"
        mask={true}
      >
        <Clients hideHeader={true} onRowSelection={onClientSelection} />
      </Modal>
    </div>
  );
};
