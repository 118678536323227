import React from 'react';
import { useParams } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { Result } from 'antd';

const StatusPage = (props) => {
  const { intl, extra } = props;
  const { status } = useParams();
  return (
    <Result
      status={status}
      title={intl.formatMessage({ id: `statusPage${status}Title` })}
      subTitle={intl.formatMessage({ id: `statusPage${status}Subtitle` })}
      extra={extra || null}
    />
  )
}

export default injectIntl(StatusPage);