import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { fetchInvoiceAmountPerMonthReport } from "../../redux/actions";
import { Button, DatePicker, Form, PageHeader, Select, Spin } from "antd";
import moment from "moment";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import _ from "lodash";

const { Option } = Select;

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    from: moment().subtract(1, 'years'),
    to: moment(),
    invoiceType: ["invoice", "other"]
  });
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    if (_.isEmpty(filter)) return;
    setLoading(true);
    dispatch(fetchInvoiceAmountPerMonthReport(filter))
      .then(({ payload }) => {
        const chartData = _.orderBy(payload.perMonth, ["year", "month"]).map((m) => ({
          name: `${m.month}/${m.year}`,
          amount: m.amount,
        }));
        setData({ chartData, total: payload.total });
      })
      .then(() => setLoading(false));
  }

  useEffect(() => fetchData(), [filter]);

  if (loading) {
    return (
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
        size="large"
      />
    );
  }

  const renderAmount = (value) =>
    `€${Math.round((value + Number.EPSILON) * 100) / 100}`;

  const formItemConfig = {
    rules: [
      {
        type: "object",
        required: true,
        message: intl.formatMessage({ id: "pleaseSelectMonth" }),
      },
    ],
  };

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "invoicesPerMonth" })}
        extra={
          <h3>
            {intl.formatMessage({ id: "total" })}:{" "}
            {renderAmount(_.get(data, "total", 0))}
          </h3>
        }
      />
      <Form
        name="filter"
        layout="inline"
        initialValues={filter}
        onFinish={(values) => setFilter(values)}
        style={{ marginBottom: 24 }}
      >
        <Form.Item
          name="from"
          label={intl.formatMessage({ id: "from" })}
          {...formItemConfig}
        >
          <DatePicker format="DD/MM/YYYY"/>
        </Form.Item>
        <Form.Item
          name="to"
          label={intl.formatMessage({ id: "to" })}
          {...formItemConfig}
        >
          <DatePicker format="DD/MM/YYYY"/>
        </Form.Item>
        <Form.Item
          name="invoiceType"
          label={intl.formatMessage({ id: "invoiceType" })}
          rules={[{ required: true, message: intl.formatMessage({ id: "fieldRequired" })}]}
        >
          <Select mode="multiple" style={{ width: 200 }} allowClear>
            <Option key="invoice" value="invoice">{intl.formatMessage({ id: "invoice" })}</Option>
            <Option key="other" value="other">{intl.formatMessage({ id: "other" })}</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({ id: "submit" })}
          </Button>
        </Form.Item>
      </Form>
      <div style={{ width: "100%", height: 600 }}>
        <ResponsiveContainer>
          <BarChart
            data={_.get(data, "chartData", [])}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              fill="#8884d8"
              dataKey="amount"
              label={{ position: 'top' }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
