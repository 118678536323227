import * as types from "./types";

const baseRoute = "v1/report";

export const fetchInvoiceAmountPerMonthReport = (filters = {}) => {
  return (api) => ({
    type: types.FETCH_INVOICE_AMOUNT_PER_MONTH,
    payload: api.post(`${baseRoute}/get-invoice-amount-per-month-report`, { filters }),
  });
};

export const fetchImportedExportedItemsReport = (filters = {}) => {
  return (api) => ({
    type: types.FETCH_IMPORTED_EXPORTED_ITEMS,
    payload: api.post(`${baseRoute}/get-imported-exported-items-report`, { filters }),
  });
};

export const fetchInItemsReport = () => {
  return (api) => ({
    type: types.FETCH_IN_ITEMS,
    payload: api.post(`${baseRoute}/get-in-items-report`),
  });
};

export const fetchClientsBalanceReport = () => {
  return (api) => ({
    type: types.FETCH_CLIENTS_BALANCE,
    payload: api.post(`${baseRoute}/clients-balance-report`),
  });
};
