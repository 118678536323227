import * as types from "./types";

const baseRoute = "v1/job";

export const searchJobs = (page, size, filters = {}, sort = {}) => {
  return (api) => ({
    type: types.SEARCH_JOBS,
    payload: api.post(`${baseRoute}/search`, { page, size, filters, sort }),
  });
};

export const searchStorage = (page, size, filters = {}, sort = {}) => {
  return (api) => ({
    type: types.SEARCH_STORAGE,
    payload: api.post(`${baseRoute}/search-storage`, { page, size, filters, sort }),
  });
};

export const fetchJob = (id) => {
  return (api) => ({
    type: types.FETCH_JOB,
    payload: api.get(`${baseRoute}/${id}`),
  });
};

export const createJob = (data) => {
  return (api) => ({
    type: types.CREATE_JOB,
    payload: api.post(baseRoute, data),
    success: {
      message: "createJob",
      description: "createJobSuccess",
    },
  });
};

export const updateJob = (id, data) => {
  return (api) => ({
    type: types.UPDATE_JOB,
    payload: api.put(`${baseRoute}/${id}`, data),
    success: {
      message: 'updateJob',
      description: 'updateJobSuccess'
    },
    showUserErrors: true
  });
};

export const completeJob = (id) => {
  return (api) => ({
    type: types.UPDATE_JOB,
    payload: api.put(`${baseRoute}/completeJob/${id}`),
    success: {
      message: 'completeJob',
      description: 'completeJobSuccess'
    },
    showUserErrors: true
  });
};

export const deleteJob = (id) => {
  return (api) => ({
    type: types.DELETE_JOB,
    payload: api.delete(`${baseRoute}/${id}`),
    id,
    success: {
      message: 'deleteJob',
      description: 'deleteJobSuccess'
    },
    showUserErrors: true
  });
};
