import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, PageHeader } from "antd";
import { searchItems } from "../../redux/actions";
import ItemTable from "./ItemTable";

export default () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState({ data: [], total: 0 });

  const fetchData = (page, size, filters = {}, sort = {}) => {
    return dispatch(searchItems(page, size, filters, sort))
      .then(({ payload }) => setData(payload));
  };

  return (
    <div className="page-wrapper">
      <PageHeader
        className="site-page-header-ghost-wrapper"
        ghost={false}
        title={intl.formatMessage({ id: "items" })}
        extra={[
          <Button
            key="create"
            type="primary"
            onClick={() => history.push("/items/create")}
          >
            {intl.formatMessage({ id: "create" })}
          </Button>,
        ]}
      />
      <ItemTable fetchData={fetchData} data={data}/>
    </div>
  );
};
