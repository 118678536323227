import React from "react";
import { Route, Switch } from "react-router-dom";
import * as Pages from "../../pages";
import { Link, Redirect, useLocation } from "react-router-dom";

const MainPageRoutes = (props) => {

  const location = useLocation();

  if (location.pathname === '/') {
    return <Redirect to="/clients" />
  }

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/clientItems">
          <Pages.ClientItems />
        </Route>
        <Route exact path="/clientItems/create">
          <Pages.ClientItemsCreate />
        </Route>
        <Route exact path="/clientItems/:id">
          <Pages.ClientItemsView />
        </Route>
        <Route exact path="/clientItems/edit/:id">
          <Pages.ClientItemsEdit />
        </Route>
      </Switch>
      <Switch>
        <Route exact path="/clients">
          <Pages.Clients />
        </Route>
        <Route exact path="/clients/create">
          <Pages.ClientsCreate />
        </Route>
        <Route exact path="/clients/:id">
          <Pages.ClientsView />
        </Route>
        <Route exact path="/clients/edit/:id">
          <Pages.ClientsEdit />
        </Route>
        <Route exact path="/clients/statement/:id">
          <Pages.ClientsStatement />
        </Route>
      </Switch>
      <Switch>
        <Route exact path="/invoices">
          <Pages.Invoices />
        </Route>
        <Route exact path="/invoices/create">
          <Pages.InvoicesCreate />
        </Route>
        <Route exact path="/invoices/:id">
          <Pages.InvoicesView />
        </Route>
        <Route exact path="/invoices/edit/:id">
          <Pages.InvoicesEdit />
        </Route>
      </Switch>
      <Switch>
        <Route exact path="/items">
          <Pages.Items />
        </Route>
        <Route exact path="/items/create">
          <Pages.ItemsCreate />
        </Route>
        <Route exact path="/items/:id">
          <Pages.ItemsView />
        </Route>
        <Route exact path="/items/edit/:id">
          <Pages.ItemsEdit />
        </Route>
      </Switch>
      <Switch>
        <Route exact path="/jobs">
          <Pages.Jobs />
        </Route>
        <Route exact path="/jobs/create">
          <Pages.JobsCreate />
        </Route>
        <Route exact path="/jobs/:id">
          <Pages.JobsView />
        </Route>
        <Route exact path="/jobs/edit/:id">
          <Pages.JobsEdit />
        </Route>
      </Switch>
      <Switch>
        <Route exact path="/payments">
          <Pages.Payments />
        </Route>
        <Route exact path="/payments/create">
          <Pages.PaymentsCreate />
        </Route>
        <Route exact path="/payments/edit/:id">
          <Pages.PaymentsEdit />
        </Route>
        <Route exact path="/payments/:id">
          <Pages.PaymentsView />
        </Route>
      </Switch>
      <Switch>
        <Route exact path="/reports/accountant-balance">
          <Pages.ReportAccountantBalance />
        </Route>
        <Route exact path="/reports/clients-balance">
          <Pages.ReportClientsBalance />
        </Route>
        <Route exact path="/reports/invoices-per-month">
          <Pages.ReportInvoicesPerMonth />
        </Route>
      </Switch>
      <Switch>
        <Route exact path="/storage">
          <Pages.Storage />
        </Route>
      </Switch>
      <Switch>
        <Route exact path="/setup">
          <Pages.Setup />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default MainPageRoutes;
